// Spinner.js
import React from 'react';
import '../../assets/css/Spinner.css';


const Spinner = () => {
    return (
        <div className="spinner" />
    );
};

export default Spinner;
