import React, { useEffect, useState } from 'react';
import { getAllBulletPoints } from './bulletPointsData';
import '../../assets/css/App.css';

function ResumePreview({ name, phone, email, education, workHistory, onCycleBulletPoint }) {
    const [workHistories, setWorkHistories] = useState([]);


    // New function to transform work history data
    const transformWorkHistory = (workHistory) => {
        return workHistory.map((item) => {
            const { facilityName, location, startDate, endDate, unit, bulletPoints, bulletPointIndices, employmentType, bedCount, traumaLevel, emrSystem } = item;

            // Use existing bullet points if available, otherwise fetch based on unit
            const points = bulletPoints || getAllBulletPoints(unit || '');
            const selectedIndices = bulletPointIndices || points.slice(0, 4).map((_, idx) => idx);

            return {
                facilityName: facilityName || null,
                location: location || null,
                unit: unit || null,
                startDate: startDate || null,
                endDate: endDate || null,
                bulletPoints: points,
                selectedPoints: selectedIndices,
                employmentType,
                bedCount,
                traumaLevel,
                emrSystem
            };
        });
    };

    useEffect(() => {
        if (Array.isArray(workHistory) && workHistory.length > 0) {
            const transformedWorkHistories = transformWorkHistory(workHistory);
            setWorkHistories(transformedWorkHistories);
        } else {
            console.log("No work history data available.");
        }
    }, [workHistory]);

    useEffect(() => {
        console.log("Updated workHistories:", workHistories);
    }, [workHistories]);

    useEffect(() => {
        console.log("Received Education:", education);
    }, [education]);





    const renderEducation = () => {
        // Safeguard against unexpected education formats
        if (!Array.isArray(education)) {
            console.error('Education is expected to be an array, received:', typeof education, education);
            // Optionally render a fallback UI or return null
            return null;
        }

        return (
            <div>
                {education.map((entry, index) => (
                    <div key={index} className="educationEntry">
                        <div className="schoolAndDate">
                            <span className="schoolName">{entry.schoolName}</span>
                            <span className="date">{entry.dates}</span>
                        </div>
                        <div className="degree">{entry.degree}</div>
                    </div>
                ))}
            </div>
        );
    };

    const renderWorkHistory = () => {
        if (!Array.isArray(workHistories) || workHistories.length === 0) {
            return <div>No work history available.</div>;
        }

        return workHistories.map((entry, entryIndex) => {
            // Construct the hospital details string with parentheses
            const hospitalDetails = [];
            if (entry.bedCount) hospitalDetails.push(`${entry.bedCount} beds`);
            if (entry.traumaLevel) hospitalDetails.push(`Trauma Level ${entry.traumaLevel}`);
            if (entry.emrSystem) hospitalDetails.push(`Charting: ${entry.emrSystem}`);
            const hospitalDetailsStr = hospitalDetails.length > 0 ? ` (${hospitalDetails.join(', ')})` : '';

            return (
                <div key={`${entry.facilityName}-${entryIndex}`} className="workHistoryEntry">
                    {/* Include the hospital details string next to the facility name */}
                    <div className="workHistoryDetail"><strong>{entry.facilityName}{hospitalDetailsStr}</strong></div>
                    <div className="workHistoryDetail">{entry.location}</div>
                    <div className="workHistoryDetail"><strong>Unit:</strong> {`${entry.unit}${entry.employmentType ? ` - ${entry.employmentType}` : ''}`}</div>

                    <div className="workHistoryDetail">{entry.startDate} - {entry.endDate}</div>
                    <div className="workHistoryBulletPointsContainer">
                        {entry.selectedPoints.map((index, idx) => (
                            <div key={`${entryIndex}-${idx}-${index}`} className="workHistoryBulletPointContainer">
                                <div className="workHistoryBulletPoint">{`• ${entry.bulletPoints[index]}`}</div>
                                <i className="fas fa-sync" onClick={() => onCycleBulletPoint(entryIndex, idx)}></i>
                            </div>
                        ))}
                    </div>
                </div>
            );
        });
    };



    return (
        <div className='previewContent'>
            <div className="resumePreview">
                <div className="resumeHeader">
                    <h1>{name}</h1>
                    {phone ? <div className='subheader'>{phone}</div> : null}
                    {/* Render email if it exists, otherwise render null */}
                    {email ? <div className='subheader'>{email}</div> : null}
                </div>
                <div className="resumeSection">
                    <h2>Education</h2>
                    {renderEducation()}
                    <hr />
                </div>
                <div className="resumeSection">
                    <h2>Work History</h2>
                    {renderWorkHistory()}
                </div>
            </div>
        </div>
    );
}

export default ResumePreview;
