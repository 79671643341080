import React, { useState } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Alert from '@mui/lab/Alert';
import axios from '../../api/axios';


const AuthModal = ({ isOpen, onCloseModal }) => {
    // State variables for authentication
    const [modalMode] = useState('login'); // 'login' or 'signup'
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [messages, setMessages] = useState({});

    // Functions for handling authentication
    const handleUsernameInput = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordInput = (event) => {
        setPassword(event.target.value);
    };

    const handleMyCustomClick = async (event, mode) => {
        if (username && password && event.target.tagName === 'BUTTON') {
            setMessages({});
            if (mode === 'login') {
                const formData = new FormData();
                formData.append('username', username);
                formData.append('password', password);
                await axios
                    .post('https://instaresumebackend.azurewebsites.net/api/login/', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        withCredentials: true,
                    })
                    .then((response) => {
                        // Perform actions after successful login
                    })
                    .catch(({ response }) => {
                        setMessages(response.data);
                    });
            }
            if (mode === 'signup') {
                const payload = { username: username, password: password, password2: password };
                await axios
                    .post('https://instaresumebackend.azurewebsites.net/api/register/', payload, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                    .then((response) => {
                        // Perform actions after successful signup
                    })
                    .catch(({ response }) => {
                        setMessages(response.data);
                    });
            }
        }
    };

    return (
        <Dialog open={isOpen} onClose={onCloseModal}>
            <DialogTitle>{modalMode === 'login' ? 'Login' : 'Signup'}</DialogTitle>
            <DialogContent>
                {messages.detail && <Alert severity="error">{messages.detail}</Alert>}
                <TextField label="Username" variant="outlined" fullWidth style={{ marginBottom: "1rem" }} onChange={handleUsernameInput} error={messages.username ? true : false} helperText={messages.username && messages.username} />
                <TextField label="Password" variant="outlined" type="password" fullWidth style={{ marginBottom: "1rem" }} onChange={handlePasswordInput} error={messages.password ? true : false} helperText={messages.password && messages.password} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={(event) => handleMyCustomClick(event, modalMode)}>{modalMode === 'login' ? 'Login' : 'Signup'}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AuthModal;