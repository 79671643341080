import React, { useState } from 'react';
import { login, register } from '../../util/auth';
import { Alert } from '@mui/material';
import { Link } from 'react-router-dom';

const AuthComponent = () => {
    const [isLogin, setIsLogin] = useState(true); // true for login, false for sign up
    const [messages, setMessages] = useState({});
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isLogin) {
            login(formData).then(({data, status}) => {
                if (status === 400 && data.hasOwnProperty('non_field_errors')) {
                    setMessages({'detail': data.non_field_errors})
                }
                if (status === 201) {
                    setMessages({})
                    window.location.href = '/resumegenerator';
                }
            })
        } else {
            register(formData).then(({data, status}) => {
                if (status === 400 && data.hasOwnProperty('non_field_errors')) {
                    setMessages({'detail': data.non_field_errors})
                }
                if (status === 201) {
                    setMessages({})
                    window.location.href = '/resumegenerator';
                }
            })
        }
        // Here you can add any API call or other actions
    };

    return (
        <div>
            <h1>{isLogin ? 'Login' : 'Sign Up'}</h1>
            {messages.detail && <Alert severity='error'>{messages.detail}</Alert>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                {isLogin && <Link to="/forgot-password">Forgot Password</Link> }
                <button type="submit">{isLogin ? 'Login' : 'Sign Up'}</button>
            </form>
            <p onClick={() => setIsLogin(!isLogin)}>
                {isLogin ? 'Need an account? Sign Up' : 'Already have an account? Login'}
            </p>
        </div>
    );
};

export default AuthComponent;
