import React, { useState } from 'react';
import { pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import axios from 'axios';
import Spinner from './Spinner'; // Directly import the Spinner component
import { Button, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Configure the axios instance with the server's URL and port
const instance = axios.create({
  baseURL: 'https://gptnode.azurewebsites.net',
  // Add any other configurations you might need
});

// Set the workerSrc for pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// Function to extract text from PDF file
async function getTextFromPdf(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const pdfData = event.target.result;
        const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
        let text = '';

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const content = await page.getTextContent();
          text += content.items.map(item => item.str).join(' ');
        }

        resolve(text);
      } catch (e) {
        console.error("Error during PDF text extraction:", e);
        reject(e);
      }
    };

    reader.onerror = (error) => {
      console.error("Error reading PDF file:", error);
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
}

// ResumeUploader React component
function ResumeUploader({ onParsed }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  // Reference to the hidden file input element
  const fileInputRef = React.useRef(null);

  // Function to trigger the hidden file input click event
  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    setSelectedFile(file);
    if (!file) {
      setIsLoading(false);
      return;
    }
    if (file) {
      try {
        const text = await getTextFromPdf(file);
        console.log('Extracted text from PDF:', text); // Log the extracted text for debugging

        // Send the text to the backend for parsing
        const response = await instance.post('/parse-resume', { text });
        console.log('Received parsedData:', response.data); // Log the response for debugging

        // Invoke the onParsed callback with the parsed data
        if (response && response.data && typeof onParsed === 'function') {
          onParsed(response.data);
        }
      } catch (error) {
        console.error("Error during file upload and parsing:", error);
      } finally {
        setIsLoading(false);
      }
    }

  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', marginTop: '1rem' }}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Button
            variant="contained"
            color="inherit"
            onClick={handleFileButtonClick}
            size="large"
            startIcon={<AddCircleIcon />}
            sx={{
              fontSize: '1rem',
              padding: '10px 24px',
              width: '220px', // Adjust the width as needed
              borderColor: 'rgba(0, 0, 0, 0.23)', // This gives a light grey border similar to other text fields
              color: 'black', // Set the text color to black
              '&:hover': {
                borderColor: 'rgba(0, 0, 0, 0.23)', // Maintain the border color on hover
                backgroundColor: 'rgba(0, 0, 0, 0.04)' // Optional: slight background color on hover
              },
              marginBottom: '20px', // Added padding below the button
            }}
          >
            Upload Resume
          </Button>
          <input
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileUpload}
            ref={fileInputRef}
            accept="application/pdf"
          />
          {selectedFile && (
            <Typography variant="subtitle1" align="center">
              {selectedFile.name}
            </Typography>
          )}
        </>
      )}
    </div>
  );
}

export default ResumeUploader;