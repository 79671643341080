import { Alert, TextField, Button } from '@mui/material';
import React, { useState } from 'react';
import { resetPassword } from '../../util/auth';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
    const [messages, setMessages] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [email, setEmail] = useState('');
    const [disabled, setDisabled] = useState(false)

    const handleResetPassword = () => {
        setMessages({})
        setSuccessMessage('')
        setDisabled(true)
        const formData = new FormData();
        formData.append('email', email)

        resetPassword(formData).then(({data, status}) => {
            setDisabled(false)
            if (status === 200) {
                setDisabled(true)
                setSuccessMessage(data.message)
            }
            if (status === 400) {
                setMessages(data)
                if (data.hasOwnProperty('non_field_errors')) {
                  setMessages({'detail': data.non_field_errors})
                }
            }
        })
    }

    return (
        <div>
            {messages.detail && <p><Alert severity="error">{messages.detail}</Alert></p>}
            {successMessage && <p><Alert severity='success'>{successMessage}</Alert></p>}
            <form>
                <TextField label="Email" variant="outlined" type="email" fullWidth style={{ marginBottom: "1rem" }} onChange={(e) => setEmail(e.target.value)} error={messages.email ? true : false} helperText={messages.email && messages.email} />
                <Button disabled={disabled} variant="contained" color="primary" onClick={handleResetPassword}>Reset Password</Button>
                <p><Link to="/home">Go back</Link></p>
            </form>
        </div>
    );
};

export default ForgotPassword;
