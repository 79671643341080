// Transition settings for Framer Motion
export const containerVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
  };
  
  export const headerVariants = {
    initial: { y: -100 },
    animate: { y: 0 },
  };
  
  export const subHeaderVariants = {
    initial: { y: 100 },
    animate: { y: 0 },
  };
  
  export const ctaVariants = {
    initial: { scale: 0.9 },
    hover: { scale: 1.1 },
  };
  