import React, { useEffect, useState, Suspense } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/App.css';  // Updated path
import Modal from '../Auth/Modal';  // Updated path

import { Button, TextField, Container, ThemeProvider, createTheme, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import Alert from '@mui/lab/Alert';
import logo from '../../assets/Images/logo-no-background.png';  // Updated path
import { getAccessToken, removeAccessToken, getAuthUser, getResumes, deleteResume, login, register } from '../../util/auth';  // Updated path
import SaveIcon from '@mui/icons-material/Save';
import HomeIcon from '@material-ui/icons/Home';
import { Hidden, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ResumePreview from './ResumePreview';  // Import ResumePreview
import WorkHistorySection from './WorkHistorySection';  // Import WorkHistorySection
import DownloadButton from './DownloadButton';  // Import DownloadButton
import SavedResumes from './SavedResumes';  // Import SavedResumes
import ResumeUploader from './ResumeUploader';
//import UserFeedbackChatBubble from './UserFeedbackChatBubble';
import { getAllBulletPoints } from './bulletPointsData';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Instructions from './instructionsComponent';
import WhatsNew from './WhatsNew';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const ResumeGenerator = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [education, setEducation] = useState([{ schoolName: '', degree: '', dates: '' }]);
    const [workHistory, setWorkHistory] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [savedResumes, setSavedResumes] = useState([]);
    const [showSavedResumesModal, setShowSavedResumesModal] = useState(false);
    const [isEditting, setIsEditting] = useState(false);
    const [currentResume, setCurrentResume] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(getAccessToken());
    const [userEmail, setUserEmail] = useState('');
    const [password, setPassword] = useState('');
    const [messages, setMessages] = useState({});
    const [user, setUser] = useState({});
    // States for modal
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('login'); // 'login' or 'signup'

    useEffect(() => {
        getAuthUser().then(data => {
            setUser(data)
            setIsLoggedIn(getAccessToken())
        })
    }, [])

    const handleOpenModal = (mode) => {
        setMessages({})
        setModalMode(mode);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleEmailInput = (event) => {
        setUserEmail(event.target.value)
    }

    const handlePasswordInput = (event) => {
        setPassword(event.target.value)
    }

    const handleMyCustomClick = async (event, mode) => {
        if (userEmail && password && event.target.tagName === 'BUTTON') {
            setMessages({})
            if (mode === 'login') {
                const formData = new FormData();
                formData.append('email', userEmail)
                formData.append('password', password)
                login(formData).then(({ data, status }) => {
                    if (status === 400 && data.hasOwnProperty('non_field_errors')) {
                        setMessages({ 'detail': data.non_field_errors })
                    }
                    if (status === 201) {
                        setMessages({})
                        window.location.reload();
                    }
                })
            }
            if (mode === 'signup') {
                const payload = {
                    email: userEmail,
                    password: password,
                    password2: password
                }
                register(payload).then(({ data, status }) => {
                    if (status === 400 && data.hasOwnProperty('non_field_errors')) {
                        setMessages({ 'detail': data.non_field_errors })
                    }
                    if (status === 201) {
                        setMessages({})
                        window.location.href = '/resumegenerator';
                    }
                })
            }
        }
    }

    const handleEducationChange = (index, field, value) => {
        setEducation(currentEducation =>
            currentEducation.map((entry, i) =>
                i === index ? { ...entry, [field]: value } : entry
            )
        );
    };

    const addEducationEntry = () => {
        setEducation(currentEducation => [...currentEducation, { schoolName: '', degree: '', dates: '' }]);
    };

    const removeEducationEntry = (index) => {
        setEducation(currentEducation => currentEducation.filter((_, i) => i !== index));
    };

    const renderEducationFields = () => {
        if (!Array.isArray(education)) {
            console.error('Expected education to be an array, received:', education);
            return null; // Or render a fallback UI
        }
        return education.map((entry, index) => (
            <Accordion key={index} sx={{ background: 'transparent', boxShadow: 'none' }} defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-education-content-${index}`}
                    id={`panel-education-header-${index}`}
                    sx={{ background: 'transparent' }}
                >
                    <Typography>{entry.schoolName || 'Education Entry'}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ background: 'transparent' }}>
                    <div key={index} style={{ marginBottom: '1rem' }}>
                        <TextField
                            label="School Name"
                            variant="outlined"
                            value={entry.schoolName}
                            onChange={(e) => handleEducationChange(index, 'schoolName', e.target.value)}
                            fullWidth
                            margin="dense"
                        />
                        <TextField
                            label="Degree"
                            variant="outlined"
                            value={entry.degree}
                            onChange={(e) => handleEducationChange(index, 'degree', e.target.value)}
                            fullWidth
                            margin="dense"
                        />
                        <TextField
                            label="Dates"
                            variant="outlined"
                            value={entry.dates}
                            onChange={(e) => handleEducationChange(index, 'dates', e.target.value)}
                            fullWidth
                            margin="dense"
                        />
                        {education.length > 1 && (
                            <Button variant="contained" color="secondary" onClick={() => removeEducationEntry(index)} sx={{ mt: 2 }}>
                                Remove
                            </Button>
                        )}
                    </div>

                    <Button variant="contained" color="primary" onClick={addEducationEntry} sx={{ mt: 2 }}>
                        Add Education Entry
                    </Button>
                </AccordionDetails>
            </Accordion>
        ));
    };



    const handleViewSavedResumes = () => {
        getResumes().then(data => {
            if (data) {
                setSavedResumes(data)
            }
        })
        setShowSavedResumesModal(true)
    }

    const handleResumeDelete = (id) => {
        deleteResume(id);
        setSavedResumes(prevResumes => prevResumes.filter(resume => resume.id !== id))
        setCurrentResume({})
        setName('')
        setPhone('')
        setEmail('')
        setEducation('')
    }

    const handleViewResume = (resume) => {
        setName(resume.name)
        setPhone(resume.phone)
        setEmail(resume.email)
        setEducation(resume.education || [{ schoolName: '', degree: '', dates: '' }]);
        setWorkHistory(resume.work_history)
        setShowSavedResumesModal(false)
    }

    const handleEditResume = (resume) => {
        setIsEditting(true)
        setCurrentResume(resume)
        setName(resume.name)
        setPhone(resume.phone)
        setEmail(resume.email)
        setEducation(resume.education || [{ schoolName: '', degree: '', dates: '' }]);
        setWorkHistory(resume.work_history)
        setShowSavedResumesModal(false)
    }

    const handleLogout = () => {
        removeAccessToken();
        window.location.reload()
    }

    const handleCycleBulletPoint = (entryIndex, bulletPointIndex) => {
        setWorkHistory(prevState => {
            return prevState.map((entry, index) => {
                if (index === entryIndex) {
                    // Ensure bulletPoints is defined and is an array
                    if (!Array.isArray(entry.bulletPoints)) {
                        console.error('bulletPoints is undefined or not an array');
                        return entry; // Return early if bulletPoints is not as expected
                    }

                    // Initialize bulletPointIndices if it's not iterable
                    const bulletPointIndices = Array.isArray(entry.bulletPointIndices) ? [...entry.bulletPointIndices] : entry.bulletPoints.map((_, idx) => idx);

                    // Calculate the next index safely
                    const currentIndex = bulletPointIndices[bulletPointIndex] || 0; // Default to 0 if undefined
                    const nextIndex = (currentIndex + 1) % entry.bulletPoints.length;

                    // Update the bulletPointIndices array
                    bulletPointIndices[bulletPointIndex] = nextIndex;

                    // Return a new entry object with the updated bulletPointIndices
                    return { ...entry, bulletPointIndices };
                }
                return entry; // For other entries, return them as they are
            });
        });
    };


    const handleParsedData = (parsedData) => {
        console.log("Received parsedData:", parsedData);
        if (parsedData) {
            console.log("Received Education Data:", parsedData.education);
            // Check if contactInfo and other properties exist before accessing them
            setName(parsedData.contactInfo?.name || '');
            setPhone(parsedData.contactInfo?.phone || '');
            setEmail(parsedData.contactInfo?.email || '');
            setEducation(parsedData.education || '');

            // Inside the handleParsedData function
            const initializedWorkHistory = parsedData.workHistory.map(entry => {
                const bulletPoints = entry.bulletPoints || getAllBulletPoints(entry.unit || '');

                // Initialize bulletPointIndices with only the first four indices
                const bulletPointIndices = Array.from({ length: Math.min(bulletPoints.length, 4) }, (_, index) => index);

                return {
                    ...entry,
                    bulletPoints,
                    bulletPointIndices // Contains only indices [0, 1, 2, 3]
                };
            });


            // Set the initialized work history to state
            setWorkHistory(initializedWorkHistory);
        }
    };




    const handleWorkHistoryUpdate = (updatedWorkHistory) => {
        setWorkHistory(updatedWorkHistory);
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        // Get coordinates of the button
        const rect = event.currentTarget.getBoundingClientRect();
        // Set anchor position
        setAnchorEl({ top: rect.bottom, left: rect.right });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileClose = () => {
        setAnchorEl(null);
    };
    const theme = createTheme();


    return (
        <ThemeProvider theme={theme}>
            <Suspense fallback={<div>Loading...</div>}>
                <Container maxWidth={false} style={{ padding: 0 }}>

                    {/* For larger screens */}
                    <Hidden mdDown implementation="css">
                        <div style={{ backgroundColor: 'white', display: 'flex', alignItems: 'center', height: '85px', paddingLeft: '25px', paddingRight: '25px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}><img src={logo} alt="Your Company Logo" style={{ height: '80px', marginRight: '20px' }} />
                            <Chip
                                label="Home"
                                component={Link}
                                to="/home"
                                icon={<HomeIcon />}
                                style={{ height: '50px', width: '100px', marginRight: '20px' }}
                            />
                            <Chip
                                icon={<SaveIcon />}
                                label="Saved Resumes"
                                onClick={handleViewSavedResumes}
                                style={{ height: '50px', width: '150px', marginRight: '20px' }}
                            />
                            <div style={{ flexGrow: 1 }}></div>
                            {isLoggedIn ? (
                                <>
                                    <Chip
                                        label={user && user.username}
                                        style={{ height: '50px', width: '100px', marginRight: '20px' }}
                                    />
                                    <Chip
                                        label="Logout"
                                        onClick={handleLogout}
                                        style={{ height: '50px', width: '100px' }}
                                    />
                                </>
                            ) : (
                                <>
                                    <IconButton onClick={handleProfileClick} style={{ marginRight: '20px' }}>
                                        <AccountCircleIcon style={{ fontSize: 40, margin: 5 }} />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleProfileClose}
                                    >
                                        <MenuItem onClick={() => { handleProfileClose(); handleOpenModal('login'); }}>Log in</MenuItem>
                                        <MenuItem onClick={() => { handleProfileClose(); handleOpenModal('signup'); }}>Sign up</MenuItem>
                                    </Menu>
                                </>
                            )}
                        </div>
                    </Hidden>
                    <ThemeProvider theme={theme}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Container maxWidth={false} style={{ padding: 0 }}>
                                {/* For larger screens */}
                                <Hidden mdDown implementation="css">
                                    <div style={{ backgroundColor: 'white', display: 'flex', alignItems: 'center', boxShadow: 'none', paddingLeft: '25px', paddingRight: '25px' }}>
                                        {/* ... */}
                                    </div>
                                </Hidden>

                                {/* Render instructions only on larger screens */}
                                <Hidden mdDown>
                                    <Instructions />
                                </Hidden>

                                {/* For smaller screens */}
                                <Hidden mdUp>
                                    <div style={{ position: 'relative', zIndex: 1, backgroundColor: 'white', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '25px',boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                                        
                                    </div>
                                   
                                </Hidden>

                               

                            </Container>
                        </Suspense>
                    </ThemeProvider>
                    {/* For smaller screens */}
                    <Hidden mdUp>
                        <div style={{ position: 'relative', zIndex: 1, backgroundColor: 'white', height: '64px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '25px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                            <IconButton onClick={handleClick}>
                                <MenuIcon style={{ fontSize: 40 }} />
                            </IconButton>
                        </div>
                        <Menu
                            anchorReference="anchorPosition"
                            anchorPosition={anchorEl || { top: 64, left: 0 }}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem component={Link} to="/home" onClick={handleClose}>
                                <HomeIcon />
                                Home
                            </MenuItem>
                            <MenuItem onClick={() => { handleClose(); handleViewSavedResumes(); }}>
                                <SaveIcon />
                                Saved Resumes
                            </MenuItem>
                            {/* Add additional menu items based on isLoggedIn */}
                            {
                                isLoggedIn ?
                                    [
                                        <MenuItem key="logout" onClick={() => { handleClose(); handleLogout(); }}>Logout</MenuItem>
                                    ]
                                    :
                                    [
                                        <MenuItem key="login" onClick={() => { handleClose(); handleOpenModal('login'); }}>Log in</MenuItem>,
                                        <MenuItem key="signup" onClick={() => { handleClose(); handleOpenModal('signup'); }}>Sign up</MenuItem>
                                    ]
                            }
                        </Menu>
                    </Hidden>

                    <div>
                    </div>

                    {/* Login/Signup Modal */}
                    <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                        <h2>{modalMode === 'login' ? 'Login' : 'Signup'}</h2>
                        {messages.detail && <p><Alert severity="error">{messages.detail}</Alert></p>}
                        <TextField label="Email" variant="outlined" fullWidth style={{ marginBottom: "1rem" }} onChange={handleEmailInput} error={messages.email ? true : false} helperText={messages.email && messages.email} />
                        <TextField label="Password" variant="outlined" type="password" fullWidth style={{ marginBottom: "1rem" }} onChange={handlePasswordInput} error={messages.password ? true : false} helperText={messages.password && messages.password} />
                        <Button variant="contained" color="primary" onClick={(event) => handleMyCustomClick(event, modalMode)}>{modalMode === 'login' ? 'Login' : 'Signup'}</Button>
                    </Modal>

                    {isEditting && <h2>Editing Resume: {currentResume.name}</h2>}
                    <div className="rowContainer" style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '20px', backgroundColor: '#42A7EA' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '1200px', backgroundColor: '#42A7EA', padding: '20px', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                            <div className="previewContainer" style={{ flex: '2', marginRight: '20px', borderRadius: '4px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', overflow: 'hidden', backgroundColor: 'white', border: '1px solid black' }}>

                                <ResumePreview
                                    key={`${name}-${phone}-${email}-${education}`}
                                    name={name}
                                    phone={phone}
                                    email={email}
                                    education={education}
                                    workHistory={workHistory}
                                    onCycleBulletPoint={handleCycleBulletPoint}
                                    onUpdateWorkHistory={handleWorkHistoryUpdate}
                                />

                            </div>

                            <div className="inputContainer" style={{ flex: '1', backgroundColor: 'white', padding: '20px', borderRadius: '4px', border: '1px solid black', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                                <ResumeUploader onParsed={handleParsedData} />

                                {successMessage && <Alert severity='success'>{successMessage}</Alert>}
                                <Accordion sx={{ background: 'transparent', boxShadow: 'none' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{ background: 'transparent' }} // Make AccordionSummary background transparent
                                    >
                                        <Typography>Add Contact Information</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ background: 'transparent' }}>
                                        <TextField
                                            className="app-input app-name-input"
                                            label="Name"
                                            variant="outlined"
                                            value={name} // Use the state variable directly
                                            onChange={e => setName(e.target.value)} // Use the setter function directly
                                            fullWidth
                                            style={{ marginBottom: "1rem" }}
                                        />

                                        <TextField
                                            className="app-input app-name-input"
                                            label="Phone"
                                            variant="outlined"
                                            value={phone} // Use the state variable directly
                                            onChange={e => setPhone(e.target.value)} // Use the setter function directly
                                            fullWidth
                                            style={{ marginBottom: "1rem" }}
                                        />

                                        <TextField
                                            className="app-input app-name-input"
                                            label="Email"
                                            variant="outlined"
                                            value={email} // Use the state variable directly
                                            onChange={e => setEmail(e.target.value)} // Use the setter function directly
                                            fullWidth
                                            style={{ marginBottom: "1rem" }}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                                {/* Dynamic Education Fields */}
                                {renderEducationFields()}

                                {/* Ensure that the TextField is closed before the next components */}
                                {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
                                <WorkHistorySection workHistory={workHistory} setWorkHistory={setWorkHistory} />
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <DownloadButton isEditting={isEditting} resumeId={currentResume ? currentResume.id : null} name={name} phone={phone} email={email} educationArray={education} workHistory={workHistory} setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage} />
                                </div>
                                {/* <UserFeedbackChatBubble position={{ bottom: '20px', right: '20px' }} />*/}
                                <Dialog open={showSavedResumesModal} onClose={() => setShowSavedResumesModal(false)}>
                                    <DialogTitle>Saved Resumes</DialogTitle>
                                    <DialogContent>
                                        <SavedResumes
                                            resumes={savedResumes}
                                            onView={(resume) => handleViewResume(resume)}
                                            onEdit={(resume) => handleEditResume(resume)}
                                            onDelete={(id) => { handleResumeDelete(id) }}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setShowSavedResumesModal(false)} color="primary">
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <div style={{ marginLeft: '20px', flex: '1', color: 'black' }}>
                                    <WhatsNew />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </Suspense>
        </ThemeProvider>
    );
};

export default ResumeGenerator;
