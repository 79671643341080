import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AuthComponent from './AuthComponent';  // Import the AuthComponent

const Modal = ({ isOpen, onClose, children, title }) => {
    if (!isOpen) return null;

    return (
        <div className="modalOverlay">
            <div className="modalContainer">
                <div className="modalHeader">
                    <h2>{title}</h2>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <IconButton
                            onClick={onClose}
                            className="closeModalButton"
                            size="large"
                            sx={{
                                width: '40px',  // Adjust the width to your desired size
                                height: '40px',  // Adjust the height to your desired size
                                borderRadius: '50%',  // Make it circular
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <div className="modalBody">
                    {/* Conditionally render AuthComponent if the title is 'Login' or 'Sign Up' */}
                    {title === 'Login' || title === 'Sign Up' ? <AuthComponent /> : children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
