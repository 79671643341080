export const specialtiesBulletPoints = {
    "Med/Surg": [
        "Conducted comprehensive patient assessments, including vital signs, pain scales, and neurological checks, to determine care needs and potential complications.",
        "Delivered expert wound care management, including dressing changes, wound vac application, and infection prevention measures.",
        "Provided specialized care for post-surgical patients, monitoring for signs of complications like bleeding, infection, or dehiscence.",
        "Administered oral, IV, and IM medications, ensuring accurate dosing, timing, and adherence to medication regimens.",
        "Inserted peripheral intravenous lines, maintained central venous access devices, and ensured patent lines for medication and fluid administration.",
        "Collaborated closely with interdisciplinary teams, including physicians, respiratory therapists, physical therapists, and case managers, to coordinate patient care.",
        "Monitored and managed patients with fluid imbalances, adjusting IV fluid rates and notifying physicians of critical lab values.",
        "Observed and interpreted telemetry rhythms, identifying arrhythmias and promptly alerting the medical team of any abnormalities.",
        "Prepared and administered blood products, following strict protocols and monitoring for transfusion reactions.",
        "Maintained and emptied surgical drains, chest tubes, and urinary catheters, documenting output and ensuring system integrity.",
        "Adhered to strict infection control protocols, particularly in cases involving MRSA, C. Diff, or other infectious conditions.",
        "Delivered effective pain management interventions, collaborating with physicians to adjust pain control regimens as necessary.",
        "Assisted in preparing patients for discharge, ensuring a smooth transition to home or secondary care facilities.",
        "Acted swiftly during patient emergencies, initiating CPR, rapid response, or code blue protocols when necessary.",
        "Demonstrated proficiency in using hospital equipment, including infusion pumps, feeding pumps, PCA pumps, and wound vac systems.",
        "Maintained meticulous patient records in electronic health systems, documenting interventions, responses, and clinical changes.",
        "Managed care for patients under various isolation precautions, ensuring protection for both patients and healthcare workers.",
        "Monitored and supported the nutritional needs of patients, whether through oral diets, enteral feeds, or parenteral nutrition.",
        "Assisted patients with mobility, employing safe patient handling techniques and using equipment like slide sheets or lifts.",
        "Regularly engaged in professional development activities, staying updated with best practices in the Med/Surg nursing field.",
    ],
    "Telemetry": [
        "Mastered continuous telemetry monitoring, proficiently interpreting and documenting cardiac rhythms for timely interventions.",
        "Employed in-depth rhythm strip analysis to promptly detect and address heart blocks, AFib, VTach, and other critical cardiac conditions.",
        "Administered cardiac-focused medications, such as antiarrhythmics, antihypertensives, and diuretics, ensuring accurate dosing and timely delivery.",
        "Swiftly and effectively responded to acute cardiac events, administering ACLS protocols, medications, and defibrillation when necessary.",
        "Demonstrated excellence in initiating and maintaining intravenous lines, especially for critical cardiac drips like amiodarone or nitroglycerin.",
        "Routinely conducted thorough assessments focused on cardiac and respiratory status, using tools like EKGs, cardiac enzymes, and blood gases.",
        "Maintained open communication and collaborated closely with cardiologists, electrophysiologists, and the broader medical team for cohesive patient care.",
        "Ensured meticulous documentation of patient rhythms, interventions, and outcomes in electronic health systems.",
        "Oversaw patients with implanted devices like pacemakers or ICDs, monitoring device function and addressing potential complications.",
        "Kept a close watch on patients' fluid and electrolyte status, particularly potassium and magnesium, crucial for cardiac function.",
        "Safely administered blood and blood products, closely monitoring for transfusion reactions or volume overload.",
        "Maintained readiness for various cardiac emergency protocols, leveraging a depth of knowledge in cardiac life support.",
        "Delivered effective cardiac-sensitive pain management, ensuring analgesics did not interfere with cardiac function.",
        "Managed patients under various isolation precautions, ensuring consistent adherence to protocols to prevent the spread of infections.",
        "Demonstrated a high level of expertise with specialized equipment, including telemetry transmitters, EKG machines, and defibrillators.",
        "Collaborated with respiratory therapists for patients requiring BiPAP or CPAP due to cardiac-related respiratory distress.",
        "Kept a close watch on cardiac enzyme levels, signaling potential myocardial damage or infarction.",
        "Implemented and advocated for robust patient safety measures, including fall prevention and pressure ulcer prevention.",
        "Stayed updated on and implemented the latest evidence-based practices for cardiac care and interventions.",
        "Regularly participated in telemetry and cardiac-focused training sessions, workshops, and seminars to ensure the highest level of care.",

    ],
    "Med/Surg Tele": [
        "Proficiently monitored and interpreted telemetry data, promptly identifying and addressing any arrhythmias or cardiac anomalies.",
        "Conducted thorough patient assessments, including vital signs, cardiac status, and neurological checks, to determine care needs and potential complications.",
        "Oversaw post-surgical patients with cardiac considerations, ensuring close monitoring and timely interventions.",
        "Delivered oral, IV, and IM medications with a particular focus on cardiac drugs such as beta-blockers, ACE inhibitors, and diuretics.",
        "Demonstrated proficiency in starting and maintaining intravenous lines, ensuring optimal venous access for drug and fluid administration.",
        "Employed advanced rhythm strip analysis skills to detect heart blocks, AFib, VTach, and other critical conditions.",
        "Engaged in consistent collaboration with cardiologists, rhythm specialists, and the interdisciplinary team to optimize patient care.",
        "Managed wound and post-surgical incision care, ensuring cleanliness, monitoring for signs of infection, and changing dressings as required.",
        "Monitored patients' fluid status closely, adjusting IV fluid rates and responding to potential fluid overload, especially in cardiac-compromised patients.",
        "Acted decisively during cardiac events, delivering ACLS protocols, medications, and defibrillation when indicated.",
        "Kept detailed patient records within electronic health systems, ensuring accurate documentation of interventions, patient responses, and rhythm strip interpretations.",
        "Administered blood products following rigorous protocols, while vigilantly monitoring for transfusion reactions.",
        "Managed patients requiring isolation precautions, employing meticulous techniques to prevent cross-contamination.",
        "Demonstrated readiness to initiate and respond to various emergency protocols, ensuring optimal patient outcomes.",
        "Utilized a range of hospital equipment, particularly telemetry units, infusion pumps, and defibrillators, with a high degree of skill.",
        "Provided effective pain management interventions, collaborating with physicians to fine-tune analgesic regimens when needed.",
        "Assisted in coordinating patient discharges, ensuring they are equipped with necessary medications, follow-up appointments, and monitoring devices.",
        "Prioritized patient safety, consistently applying fall prevention measures, bed alarms, and other protective strategies.",
        "Demonstrated expertise in the continuous monitoring of multiple patients simultaneously, ensuring timely intervention in emergent situations.",
        "Participated in regular skills training and workshops to stay updated on the latest best practices in telemetry and Med/Surg care.",
    ],
    "Stepdown": [
        "Oversaw patients transitioning from intensive care, ensuring consistent surveillance and timely interventions.",
        "Administered a variety of medications including vasopressors, anticoagulants, and inotropes while monitoring for potential side effects.",
        "Collaborated with respiratory therapists in managing patients on BiPAP, CPAP, and high-flow nasal cannula, ensuring optimal oxygenation.",
        "Utilized telemetry to continuously monitor patients' cardiac rhythms, swiftly detecting and responding to any arrhythmias.",
        "Safely initiated and maintained intravenous therapies, including total parenteral nutrition, antibiotic infusions, and blood products.",
        "Managed intricate wound care tasks, including negative pressure wound therapy, and drain maintenance.",
        "Monitored post-operative patients, ensuring vital signs stability, incision care, and pain management.",
        "Managed central lines, arterial lines, and PICCs, ensuring line integrity, preventing infections, and monitoring for complications.",
        "Delivered effective and consistent pain management regimens, including PCA pumps and nerve block infusions.",
        "Vigilantly monitored and adjusted IV fluid rates, paying close attention to patients' hydration status and electrolyte balance.",
        "Engaged regularly with interdisciplinary teams including physicians, pharmacists, physical therapists, and case managers to formulate cohesive care plans.",
        "Conducted frequent neurological checks on patients with head injuries, strokes, or post-neurosurgical interventions.",
        "Ensured rigorous documentation of patient progress, interventions, and responses in electronic health record systems.",
        "Closely monitored hemodynamic status, adjusting medications and interventions to maintain optimal blood pressure and cardiac output.",
        "Prioritized and implemented patient safety measures, from fall prevention to infection control protocols.",
        "Assisted respiratory therapists with ventilator weaning processes, monitoring patients for signs of respiratory distress.",
        "Collaborated with dietitians to manage enteral and parenteral nutrition for patients with dietary restrictions or feeding tubes.",
        "Demonstrated quick and effective response to sudden patient emergencies, initiating advanced life support protocols when necessary.",
        "Skilled in the use of various medical equipment such as infusion pumps, EKG machines, and mechanical ventilators.",
        "Actively participated in ongoing training, certifications, and seminars to stay abreast of current ICU stepdown practices and innovations.",
    ],
    "ICU": [
        "Proficiently provided advanced life support in critical situations, ensuring timely and appropriate interventions.",
        "Collaborated with respiratory therapists to manage patients on mechanical ventilation, adjusting settings based on patient's respiratory status and arterial blood gas results.",
        "Skilled in the use of advanced monitoring tools, such as Swan-Ganz catheters, to obtain detailed hemodynamic data and adjust treatments accordingly.",
        "Expertly titrated vasoactive medications to maintain optimal blood pressures and tissue perfusion.",
        "Managed multiple simultaneous drug infusions, ensuring correct dosages and rapid adjustments in response to patient status.",
        "Actively participated in rapid response teams, providing swift assistance in emergent situations across the facility.",
        "Conducted care for intricate wound types, including surgical incisions, pressure ulcers, and burns.",
        "Effectively managed and maintained central lines, arterial lines, and PICCs, mitigating risk of complications.",
        "Oversaw post-surgical patients, monitoring for complications and ensuring optimal recovery from anesthesia.",
        "Continuously assessed patients’ hydration status and electrolyte balance, intervening with appropriate fluid or medication regimens.",
        "Quickly identified signs of sepsis and initiated evidence-based sepsis protocols to maximize patient outcomes.",
        "Expertly balanced analgesic and sedative medications, ensuring patient comfort while maintaining necessary alertness levels.",
        "Assisted in the management of patients on renal replacement therapies, including CRRT, and other organ support modalities.",
        "Played an instrumental role in leading or assisting during code blue events, ensuring efficient and effective teamwork.",
        "Competently assisted physicians in various bedside procedures such as lumbar punctures, central line placements, and chest tube insertions.",
        "Coordinated with dietitians to ensure appropriate nutritional support, managing parenteral and enteral feeding regimens.",
        "Cared for patients with multisystem involvement, coordinating a multidisciplinary approach to address all active issues.",
        "Ensured rigorous, timely, and accurate documentation of patient status, interventions, and outcomes using electronic health record systems.",
        "Demonstrated proficiency in using ICU-specific medical equipment, including ventilators, infusion pumps, and monitoring systems.",
        "Actively collaborated with a diverse team of healthcare professionals, including physicians, respiratory therapists, pharmacists, and physical therapists to provide comprehensive patient care.",
    ],
    "CVICU": [
        "Demonstrated expertise in advanced cardiac monitoring, recognizing subtle changes in hemodynamics and EKG readings.",
        "Specialized in post-operative care for patients following cardiovascular surgeries, including CABG, valve replacements, and transplants.",
        "Proficient in managing patients on intra-aortic balloon pumps (IABP), ventricular assist devices (VAD), and extracorporeal membrane oxygenation (ECMO).",
        "Administered and titrated vasoactive medications, ensuring optimal cardiac output and tissue perfusion.",
        "Led and participated in rapid response and code teams, demonstrating quick decision-making and interventions in crisis situations.",
        "Skilled in utilizing Swan-Ganz catheters and other advanced tools to obtain real-time readings on cardiac pressures and output.",
        "Collaborated with respiratory therapists in managing patients on mechanical ventilation, ensuring optimal settings for those with cardiovascular impairments.",
        "Maintained and assessed central venous, pulmonary artery, and arterial lines, ensuring accurate monitoring and minimizing risk of infections.",
        "Administered and monitored effects of antiarrhythmic medications, ensuring heart rhythm stability.",
        "Assisted in sterile bedside procedures, including central line placements, pericardiocentesis, and thoracentesis.",
        "Meticulously balanced patients' fluid status to optimize cardiac function, often using diuretics and ultrafiltration techniques.",
        "Monitored patients with temporary pacemakers and other implanted cardiac devices, identifying and addressing potential malfunctions.",
        "Safely administered blood and other related products, monitoring for potential transfusion reactions.",
        "Provided care for complex surgical incisions, ensuring cleanliness, promoting healing, and identifying potential complications early on.",
        "Administered thrombolytic agents for patients with acute coronary syndromes or pulmonary embolisms, monitoring for potential bleeding complications.",
        "Managed patients undergoing therapeutic hypothermia following cardiac arrest, ensuring appropriate cooling and rewarming processes.",
        "Supported patients requiring CRRT due to renal impairment in the context of their cardiovascular condition.",
        "Managed pain and sedation regimens, ensuring comfort without compromising cardiovascular stability.",
        "Worked closely with cardiologists, cardiac surgeons, and other specialists to ensure a comprehensive approach to patient care.",
        "Participated in CVICU quality improvement initiatives, ensuring the delivery of top-tier, evidence-based care.",
    ],
    "Neuro ICU": [
        "Advanced Neurological Monitoring: Proficiently managed continuous electroencephalography (cEEG) and interpreted data to detect early signs of cerebral changes.",
        "Oversaw the recovery of patients following intricate neurosurgical procedures, ensuring stability and timely interventions.",
        "Expertly monitored and managed intracranial pressure (ICP), employing interventions to maintain optimal cerebral perfusion.",
        "Conducted comprehensive neurological examinations, including cranial nerve assessments, to promptly detect neurological deficits.",
        "Delivered acute care to stroke patients, ensuring swift interventions to minimize cerebral damage.",
        "Managed patients with spinal cord injuries, vigilantly monitoring for complications and changes in neurological function.",
        "Collaborated with respiratory therapists to manage neuro patients requiring mechanical ventilation, ensuring optimal oxygenation and ventilation.",
        "Pharmacological Interventions: Administered and monitored neuro-specific medications, ensuring therapeutic levels and monitoring for potential side effects.",
        "Emergency Interventions: Reacted promptly to neurological emergencies such as seizures, ensuring safety and implementing first-line treatments.",
        "Invasive Monitoring: Managed patients with external ventricular drains (EVDs), lumbar drains, and other invasive neuro monitoring devices.",
        "Collaboration with Neuro Specialists: Worked closely with neurologists, neurosurgeons, and neuroradiologists to optimize patient care.",
        "Administered paralytic agents when required, while ensuring adequate sedation and monitoring for potential complications.",
        "Quality Improvement Initiatives: Participated in initiatives aiming to enhance the quality of neurocritical care, implementing evidence-based practices.",
        "Swiftly triaged incoming neuro patients, ensuring those with the most critical needs received immediate attention.",
        "Implemented and maintained seizure precautions for at-risk patients, ensuring safety and rapid intervention.",
        "Provided specialized neurocritical care during life-threatening situations based on the latest guidelines.",
        "Oversaw fluid balances, ensuring optimal cerebral perfusion and managing potential cerebral edema.",
        "Collaborated in interdisciplinary care planning, integrating neurological insights to guide comprehensive patient care.",
        "Upheld safety protocols, preventing potential complications like falls, aspiration, or pressure injuries in neurologically compromised patients.",
        "Engaged in clinical research projects, contributing to advancements in neurocritical care."
    ],
    "Labor and Delivery": [
        "Monitored and cared for patients in all stages of labor, ensuring the well-being of both mother and fetus.",
        "Skillfully interpreted electronic fetal heart rate monitoring, promptly identifying and addressing signs of fetal distress.",
        "Administered and monitored various pain management strategies, including epidurals, spinal blocks, and intravenous analgesics.",
        "Actively assisted obstetricians and midwives during vaginal births, providing safety and support for both the mother and baby.",
        "Managed maternal well-being post-delivery, monitoring for complications such as hemorrhage and ensuring stabilization.",
        "Prepared patients for cesarean delivery, meticulously following all pre-operative protocols.",
        "Supported surgical teams during cesarean deliveries, ensuring instrument handling and maintaining a sterile environment.",
        "Oversaw immediate post-operative care for mothers after cesarean delivery, monitoring vital signs, wound status, and pain levels.",
        "Coordinated post-cesarean patient transitions to recovery areas or postpartum units, ensuring a smooth handoff and continuity of care.",
        "Vigilantly assessed and responded to potential post-operative complications like infections, blood clots, or hemorrhage.",
        "Managed patients undergoing labor induction, administering medications, and monitoring progress and well-being.",
        "Ensured all delivery instruments, from forceps to vacuum extractors, were sterilized and prepared for use.",
        "Provided initial assessment and care for newborns, ensuring stability and initiating the first moments of skin-to-skin contact.",
        "Rapidly intervened in obstetrical emergencies such as shoulder dystocia, cord prolapse, or maternal hemorrhage.",
        "Assessed and triaged incoming patients, determining the stage and urgency of labor and any potential complications.",
        "Worked seamlessly with obstetricians, anesthesiologists, pediatricians, and other healthcare professionals during labor and delivery processes.",
        "Identified and intervened in cases of postpartum hemorrhage, utilizing medications and manual techniques to ensure maternal safety.",
        "Adhered to strict infection control protocols, especially in the operating room, to prevent post-operative infections.",
        "Pursued and maintained certifications such as fetal heart monitoring, neonatal resuscitation, and advanced cardiac life support.",
        "Actively contributed to labor and delivery quality improvement initiatives, refining practices to enhance patient outcomes.",
    ],
    "Interventional Radiology": [
        "Proficiently managed continuous electroencephalography (cEEG) and interpreted data to detect early signs of cerebral changes.",
        "Oversaw the recovery of patients following intricate neurosurgical procedures, ensuring stability and timely interventions.",
        "Expertly monitored and managed intracranial pressure (ICP), employing interventions to maintain optimal cerebral perfusion.",
        "Conducted comprehensive neurological examinations, including cranial nerve assessments, to promptly detect neurological deficits.",
        "Delivered acute care to stroke patients, ensuring swift interventions to minimize cerebral damage.",
        "Managed patients with spinal cord injuries, vigilantly monitoring for complications and changes in neurological function.",
        "Collaborated with respiratory therapists to manage neuro patients requiring mechanical ventilation, ensuring optimal oxygenation and ventilation.",
        "Administered and monitored neuro-specific medications, ensuring therapeutic levels and monitoring for potential side effects.",
        "Reacted promptly to neurological emergencies such as seizures, ensuring safety and implementing first-line treatments.",
        "Managed patients with external ventricular drains (EVDs), lumbar drains, and other invasive neuro monitoring devices.",
        "Worked closely with neurologists, neurosurgeons, and neuroradiologists to optimize patient care.",
        "Administered paralytic agents when required, while ensuring adequate sedation and monitoring for potential complications.",
        "Participated in initiatives aiming to enhance the quality of neurocritical care, implementing evidence-based practices.",
        "Swiftly triaged incoming neuro patients, ensuring those with the most critical needs received immediate attention.",
        "Implemented and maintained seizure precautions for at-risk patients, ensuring safety and rapid intervention.",
        "Provided specialized neurocritical care during life-threatening situations based on the latest guidelines.",
        "Oversaw fluid balances, ensuring optimal cerebral perfusion and managing potential cerebral edema.",
        "Collaborated in interdisciplinary care planning, integrating neurological insights to guide comprehensive patient care.",
        "Upheld safety protocols, preventing potential complications like falls, aspiration, or pressure injuries in neurologically compromised patients.",
        "Engaged in clinical research projects, contributing to advancements in neurocritical care."
    ],
    "Cath Lab": [
        "Assisted in preparing patients for catheterization, ensuring proper positioning and a sterile environment.",
        "Proficiently monitored and interpreted intricate hemodynamic data, promptly identifying and addressing abnormalities.",
        "Safely administered conscious sedation, analgesics, and other required medications during procedures, ensuring patient comfort and safety.",
        "Supported cardiologists during diagnostic and interventional procedures, facilitating instrument handling and maintaining a sterile field.",
        "Rapidly responded to intraoperative complications, such as arrhythmias, administering appropriate treatments and notifying the interventional team.",
        "Monitored patients post-catheterization, assessing for potential complications like hematomas or altered limb perfusion.",
        "Collaborated with the radiological team to ensure optimal imaging during procedures, adjusting settings as necessary.",
        "Assisted in the placement and management of invasive lines, such as arterial and central venous lines.",
        "Administered contrast media during angiograms, vigilantly monitoring for allergic reactions or complications.",
        "Provided support during the implantation of devices like pacemakers and internal cardiac defibrillators, ensuring a sterile environment and patient stability.",
        "Evaluated incoming patients, prioritizing based on the severity and urgency of cardiovascular symptoms.",
        "Participated in electrophysiological studies, monitoring heart rhythms, and assisting in the ablation of arrhythmogenic foci.",
        "Ensured all catheterization instruments were meticulously sterilized and prepared for procedures.",
        "Worked seamlessly with cardiologists, radiologists, and other healthcare professionals to ensure high-quality patient care.",
        "Engaged in cath lab quality improvement projects, refining practices based on evidence and feedback.",
        "Drew arterial blood samples for gas analysis and interpreted results, guiding treatment modalities during catheterizations.",
        "Expertly utilized vascular closure devices post-catheterization, ensuring hemostasis and minimizing complications.",
        "Adhered to radiation safety measures, minimizing exposure risk to both patients and healthcare professionals.",
        "Actively pursued additional training and certifications, keeping abreast of the latest cath lab practices and technologies.",
        "Diligently documented procedural data, patient responses, and outcomes in electronic health records, ensuring accuracy and detailed accounts of each procedure.",
    ],
    "ED": [
        "Assessed and prioritized incoming patients based on the severity and urgency of symptoms, ensuring timely care for the most critical cases.",
        "Administered advanced life support measures during cardiac and respiratory arrest situations, playing a vital role in resuscitation efforts.",
        "Provided immediate interventions for acute medical emergencies such as stroke, myocardial infarction, respiratory distress, and overdose.",
        "Managed pediatric emergencies with precision, understanding the unique needs and presentations of younger patients.",
        "Cleaned, sutured, stapled, or dressed wounds, burns, and lacerations, ensuring optimal healing conditions and infection prevention.",
        "Assisted physicians in procedures like central line placement, intubation, thoracentesis, and lumbar punctures.",
        "Managed and treated patients experiencing drug overdoses, administering reversal agents and monitoring for complications.",
        "Continuously monitored patients' vital signs and interpreted advanced hemodynamic parameters to guide therapeutic decisions.",
        "Splinted fractures, reduced dislocations, and provided immediate care for other musculoskeletal injuries.",
        "Skillfully handled multiple critically ill patients simultaneously during high-volume or high-acuity situations.",
        "Ordered and interpreted preliminary lab and diagnostic imaging results, facilitating timely interventions based on findings.",
        "Assessed pain levels and administered appropriate analgesics or interventions, ensuring patient comfort without compromising safety.",
        "Provided compassionate care for patients in mental health crises, coordinating with psychiatric services as necessary.",
        "Played a key role during mass casualty incidents or disaster scenarios, ensuring effective triage and resource utilization.",
        "Upheld strict infection control protocols, especially during outbreak situations or with potentially contagious patients.",
        "Liaised effectively with other hospital departments, ensuring smooth transitions for admitted patients and optimal care.",
        "Assisted in emergency childbirth situations, ensuring the well-being of both the mother and child.",
        "Managed post-arrest patients, stabilizing and preparing them for transfer to the ICU or appropriate units.",
        "Expertly operated and maintained diverse emergency equipment, from defibrillators to mechanical ventilators.",
        "Regularly participated in simulation trainings, drills, and courses to remain at the forefront of emergency nursing practices.",

    ],
    "OR": [
        "Demonstrated proficiency in scrubbing for various surgical procedures, maintaining a sterile field, and passing instruments efficiently to surgical teams.",
        "Oversaw the broader environment of the surgical suite, ensuring patient safety, equipment availability, and effective communication among the team.",
        "Positioned patients correctly based on surgical procedure requirements, ensuring optimal access for surgeons while preserving patient safety.",
        "Ensured all surgical instruments and equipment underwent rigorous sterilization processes, minimizing infection risks.",
        "Vigilantly monitored patients’ vital signs and physiological responses during surgery, alerting the surgical team to any deviations.",
        "Prepared operating rooms with appropriate tools and equipment, verifying surgical checklists, and ensuring readiness for procedures.",
        "Rigorously performed and documented instrument, sponge, and needle counts before, during, and after procedures to ensure patient safety.",
        "Accurately documented all procedural steps, patient responses, and equipment use in surgical logs and electronic health records.",
        "Rapidly adapted to emergency surgical situations, showcasing flexibility and efficiency under high-pressure conditions.",
        "Worked seamlessly alongside surgeons, anesthesiologists, surgical technologists, and other OR staff to ensure successful surgical outcomes.",
        "Proficiently operated a variety of OR equipment, including electrocautery devices, surgical lasers, and robotic assistance devices.",
        "Accurately labeled, preserved, and sent surgical specimens for further analysis, ensuring integrity and appropriate chain of custody.",
        "Assisted in the stabilization and transfer of postoperative patients, ensuring a smooth handoff to recovery room teams.",
        "Actively participated in inventory checks, ensuring the OR was consistently stocked with necessary supplies and equipment.",
        "Adhered to strict infection control measures, ensuring a clean and safe environment for all surgical procedures.",
        "Participated in ongoing surgical workshops, trainings, and simulations to stay updated with the latest OR techniques and best practices.",
        "Assisted in intricate surgical procedures, from organ transplants to neurosurgeries, showcasing versatility and expertise.",
        "Championed OR safety protocols, proactively identifying and addressing potential hazards.",
        "Served as a vital communication bridge between the surgical field and the outer OR, ensuring timely relay of information.",
        "Engaged in regular OR quality improvement initiatives, optimizing processes to enhance patient outcomes and team efficiency.",
    ],
    "NICU": [
        "Advanced Neurological Monitoring: Proficiently managed continuous electroencephalography (cEEG) and interpreted data to detect early signs of cerebral changes.",
        "Post-Operative Care: Oversaw the recovery of patients following intricate neurosurgical procedures, ensuring stability and timely interventions.",
        "Intracranial Pressure Management: Expertly monitored and managed intracranial pressure (ICP), employing interventions to maintain optimal cerebral perfusion.",
        "Neurological Assessments: Conducted comprehensive neurological examinations, including cranial nerve assessments, to promptly detect neurological deficits.",
        "Stroke Care: Delivered acute care to stroke patients, ensuring swift interventions to minimize cerebral damage.",
        "Spinal Cord Monitoring: Managed patients with spinal cord injuries, vigilantly monitoring for complications and changes in neurological function.",
        "Mechanical Ventilation: Collaborated with respiratory therapists to manage neuro patients requiring mechanical ventilation, ensuring optimal oxygenation and ventilation.",
        "Pharmacological Interventions: Administered and monitored neuro-specific medications, ensuring therapeutic levels and monitoring for potential side effects.",
        "Emergency Interventions: Reacted promptly to neurological emergencies such as seizures, ensuring safety and implementing first-line treatments.",
        "Invasive Monitoring: Managed patients with external ventricular drains (EVDs), lumbar drains, and other invasive neuro monitoring devices.",
        "Collaboration with Neuro Specialists: Worked closely with neurologists, neurosurgeons, and neuroradiologists to optimize patient care.",
        "Management of Paralytics: Administered paralytic agents when required, while ensuring adequate sedation and monitoring for potential complications.",
        "Quality Improvement Initiatives: Participated in initiatives aiming to enhance the quality of neurocritical care, implementing evidence-based practices.",
        "Triage and Prioritization: Swiftly triaged incoming neuro patients, ensuring those with the most critical needs received immediate attention.",
        "Seizure Precautions: Implemented and maintained seizure precautions for at-risk patients, ensuring safety and rapid intervention.",
        "Advanced Life Support: Provided specialized neurocritical care during life-threatening situations based on the latest guidelines.",
        "Fluid and Electrolyte Management: Oversaw fluid balances, ensuring optimal cerebral perfusion and managing potential cerebral edema.",
        "Multidisciplinary Care Planning: Collaborated in interdisciplinary care planning, integrating neurological insights to guide comprehensive patient care.",
        "Safety Protocols: Upheld safety protocols, preventing potential complications like falls, aspiration, or pressure injuries in neurologically compromised patients.",
        "Clinical Research Participation: Engaged in clinical research projects, contributing to advancements in neurocritical care."
    ],
    "PICU": [
        "Administered pediatric advanced life support measures, rapidly responding to cardiac and respiratory emergencies.",
        "Managed and adjusted settings for pediatric patients on mechanical ventilation, ensuring optimal oxygenation and lung protection.",
        "Accurately calculated, prepared, and delivered a wide array of pediatric medications, considering age and weight-specific doses.",
        "Vigilantly monitored vital signs, intracranial pressures, and other critical parameters, identifying potential concerns at the earliest.",
        "Managed post-operative pediatric patients, monitoring for complications and ensuring optimal recovery after procedures.",
        "Led and participated in code blue responses, utilizing pediatric-specific resuscitation techniques and equipment.",
        "Assisted physicians with procedures like lumbar punctures, central line placements, and bronchoscopies, ensuring child comfort and safety.",
        "Delivered prompt interventions for pediatric patients presenting with septic shock, optimizing survival rates.",
        "Maintained stringent infection prevention measures tailored to the pediatric population's unique vulnerabilities.",
        "Provided comprehensive care for children with multi-system involvement, understanding the intricacies of diverse illnesses and injuries.",
        "Skillfully interpreted advanced hemodynamic parameters to guide fluid and vasoactive medication management.",
        "Offered pre and post-operative care for pediatric patients undergoing organ transplants, focusing on graft health and anti-rejection management.",
        "Delivered specialized care for children with significant burns or trauma, managing pain and promoting healing.",
        "Assessed and ensured appropriate nutritional support, whether through enteral or parenteral routes, considering pediatric-specific needs.",
        "Responded to emergencies associated with pediatric cancer patients, such as tumor lysis syndrome or neutropenic fever.",
        "Demonstrated expertise in operating specialized pediatric equipment like high-frequency oscillatory ventilators and intra-aortic balloon pumps.",
        "Used pediatric-specific scales and methods to assess and manage pain, ensuring patient comfort.",
        "Supported pediatric patients and their teams during end-of-life decisions and care, ensuring comfort and dignity.",
        "Seamlessly coordinated with a multidisciplinary team, including pediatric intensivists, respiratory therapists, and child life specialists, to provide holistic care.",
        "Regularly attended pediatric-specific seminars, workshops, and simulation trainings to stay abreast of the latest PICU practices.",
    ],
    "Endoscopy": [
        "Actively assisted gastroenterologists during endoscopic procedures, such as colonoscopies and upper endoscopies, ensuring smooth workflow.",
        "Administered and monitored conscious sedation, ensuring patient comfort while maintaining a safe level of sedation throughout the procedure.",
        "Ensured optimal positioning of patients during procedures to facilitate access and visualization for the endoscopist.",
        "Managed and passed endoscopic instruments proficiently, aiding in biopsy collections or polyp removals when required.",
        "Demonstrated expertise in cleaning, disinfecting, and maintaining endoscopes, ensuring equipment longevity and patient safety.",
        "Continuously monitored patients' vital signs during procedures, quickly identifying and addressing any abnormal findings.",
        "Monitored patients during recovery from sedation, ensuring their safety, alertness, and stable vitals before discharge.",
        "Adhered to rigorous infection control measures in the endoscopy suite, minimizing cross-contamination risks.",
        "Prepared and trained to promptly respond to emergencies during endoscopic procedures, such as hemorrhage or perforation.",
        "Accurately labeled and preserved biopsy specimens, ensuring they're sent to pathology with appropriate documentation.",
        "Assisted during advanced procedures like ERCPs, managing fluoroscopic equipment and aiding in stone removal or stent placements.",
        "Collaborated in therapeutic endoscopic procedures, including dilations, stent placements, or hemorrhage controls.",
        "Verified completion of bowel prep or fasting guidelines and ensured readiness of patients for the endoscopic procedures.",
        "Efficiently managed endoscopy suite turnover, ensuring timely setup for successive cases and maintaining procedure schedules.",
        "Operated specialized equipment such as endoscopic ultrasound devices or argon plasma coagulation units proficiently.",
        "Meticulously documented procedural details, interventions, and patient responses in electronic health records.",
        "Worked seamlessly with gastroenterologists, anesthesia teams, technicians, and unit clerks to optimize patient care and workflow.",
        "Participated in ongoing endoscopy training sessions, certifications, and workshops to remain updated with the latest practices.",
        "Proactively identified and mitigated potential hazards in the endoscopy suite, ensuring patient safety at all times.",
        "Engaged in quality control initiatives for the endoscopy unit, participating in equipment checks, protocol reviews, and procedure optimizations.",
    ],
    "PACU": [
        "Provided immediate post-operative care to surgical patients, ensuring a smooth transition from anesthesia to consciousness.",
        "Continuously assessed and recorded patients' vital signs, detecting and responding swiftly to any abnormal findings.",
        "Recognized and managed complications related to anesthesia, such as malignant hyperthermia, respiratory depression, or anaphylaxis.",
        "Administered pain medications as prescribed, consistently evaluating their effectiveness and adjusting for optimal pain control.",
        "Managed airways and provided respiratory support when necessary, using equipment such as nasal cannula, non-rebreather masks, or BiPAP machines.",
        "Analyzed cardiac rhythms on telemetry, identifying arrhythmias or ischemic changes and initiating prompt interventions.",
        "Assessed surgical sites for signs of bleeding, infection, or other complications, and applied dressings as necessary.",
        "Administered IV fluids and blood products, monitoring for potential reactions and ensuring optimal hydration and circulation.",
        "Conducted regular neurological checks, particularly for patients emerging from neurosurgical procedures, to gauge any deficits or changes.",
        "Employed warming blankets, forced air warmers, and other means to maintain normothermia in post-operative patients.",
        "Safely and accurately delivered a range of medications, from antibiotics to vasoactive drugs, considering patient-specific needs and conditions.",
        "Efficiently prepared and transferred stable patients to appropriate units or discharge, ensuring a seamless flow in a fast-paced recovery environment.",
        "Maintained close communication with surgical and anesthesia teams, updating on patient status and obtaining necessary handover details.",
        "Assisted anesthesia providers with intubation or extubation procedures, ensuring airway security and patient comfort.",
        "Diligently recorded patient assessments, interventions, and outcomes in electronic health records, ensuring data accuracy.",
        "Identified early signs of sepsis in post-operative patients and initiated sepsis protocols as required.",
        "Strictly adhered to established PACU clinical protocols and standards of care, ensuring patient safety and optimal outcomes.",
        "Maintained proficiency in emergency response protocols, ensuring preparedness for unexpected post-operative complications.",
        "Demonstrated expertise in using PACU-specific equipment, such as PCA pumps, nerve block pumps, and advanced monitoring devices.",
        "Engaged in ongoing PACU-focused education and simulations, staying updated with best practices and advancements in post-operative care.",
    ],
    "School Nurse": [
        "Provided health services to students and staff.",
        "Administered medications and treatments as needed.",
        "Conducted health screenings and assessments.",
        "Managed and documented student health records.",
        "Collaborated with teachers and parents on health-related concerns.",
        "Responded to medical emergencies and injuries.",
        "Implemented infection control and disease prevention measures.",
        "Developed health care plans for students with chronic conditions.",
        "Educated students and staff on health and wellness topics.",
        "Promoted a healthy and safe school environment.",
    ],

    "CT Tech": ["Masterfully performed a variety of CT scan procedures, including head, chest, abdomen, and extremities.",
        "Safely administered oral and IV contrast agents to enhance image clarity while monitoring for adverse reactions.",
        "Fine-tuned machine parameters to optimize image quality, ensuring the capture of precise and high-resolution scans.",
        "Rapidly and accurately conducted urgent scans, providing timely diagnostic information for critical patient scenarios.",
        "Skillfully positioned patients to achieve optimal image acquisition without compromising comfort.",
        "Scheduled and ensured regular equipment maintenance, ensuring machine efficiency and preventing downtime.",
        "Utilized advanced software for 3D image reconstruction, assisting radiologists in better visualization and diagnosis.",
        "Adhered to ALARA principles (As Low As Reasonably Achievable) to minimize radiation exposure to patients and staff.",
        "Regularly reviewed and evaluated CT images for quality, ensuring consistent and clear diagnostic data.",
        "Demonstrated a strong understanding of cross-sectional anatomy to identify abnormalities during scans.",
        "Worked closely with radiologists to ensure scans met specific diagnostic needs and modified techniques when necessary.",
        "Applied specialized techniques for pediatric patients, ensuring minimal radiation exposure and capturing accurate images.",
        "Utilized advanced post-processing tools to highlight specific areas of interest on captured images.",
        "Maintained meticulous documentation of scan details, patient histories, and any observed anomalies.",
        "Assisted radiologists during CT-guided biopsies and other interventional procedures.",
        "Prioritized and managed scan schedules to maintain a consistent and efficient workflow in the department.",
        "Monitored and documented radiation doses for each procedure, ensuring they remained within safe limits.",
        "Remained updated on the latest advancements in CT technology and incorporated new techniques into practice.",
        "Familiarized with emergency protocols, ensuring rapid response during contrast reactions or patient complications.",
        "Collaborated in the development and updating of CT protocols, optimizing techniques and ensuring alignment with current best practices.",],

    "Rad Tech": ["Proficiently conducted a wide range of radiographic examinations, from basic X-rays to complex procedures.",
        "Demonstrated expertise in correctly and comfortably positioning patients to ensure optimal image acquisition.",
        "Administered and monitored patients receiving contrast agents for specialized diagnostic studies, observing for adverse reactions.",
        "Committed to adhering to ALARA principles to safeguard patients and staff from unnecessary radiation exposure.",
        "Utilized advanced digital radiography equipment to produce high-resolution images, ensuring rapid availability for diagnosis.",
        "Collaborated with radiologists, ensuring images met diagnostic needs and quickly retaking images if necessary.",
        "Operated mobile X-ray equipment for bedside imaging, particularly in critical care areas and the OR.",
        "Gained experience in performing fluoroscopy-guided studies, such as barium swallows or joint injections.",
        "Managed digital image archiving through PACS (Picture Archiving and Communication System), ensuring prompt and organized storage.",
        "Quality Control: Engaged in routine quality control tests on radiographic equipment, maintaining optimal performance.",
        "Shielding Protocols: Utilized proper shielding techniques to protect areas not being imaged, especially vital for pediatric and pregnant patients.",
        "Technique Adjustment: Adjusted radiographic techniques based on patient size, tissue density, and suspected pathology to capture clear images.",
        "Routine Maintenance: Performed basic equipment maintenance and troubleshooting, reducing downtime and ensuring consistent image quality.",
        "Emergency Response: Acted quickly to perform immediate X-rays in emergent scenarios, providing essential diagnostic information.",
        "Interventional Assistance: Supported interventional radiologists during procedures, such as angiographies or lumbar punctures.",
        "Organ Dose Monitoring: Monitored and documented radiation doses for each examination, ensuring doses remained within recommended limits.",
        "Procedure Preparation: Readied rooms and equipment for specialized procedures, ensuring all necessary supplies were available.",
        "Diverse Patient Care: Adapted techniques for a diverse patient population, from infants to the elderly, ensuring appropriate care.",
        "Collaboration: Worked seamlessly with a multidisciplinary team, from nurses to surgeons, to coordinate patient imaging.",
        "Continuous Learning: Stayed updated on the latest advancements in radiography, incorporating new techniques and technologies into daily practice.",
    ],

    "Certified Surgical Tech (CST)": ["Instrument Preparation: Efficiently prepared, organized, and maintained sterile instrument trays for various surgical procedures.",
        "Proficiently assisted surgeons during procedures, ensuring timely provision of required instruments and materials.",
        "Upheld the highest standards of aseptic technique to prevent surgical site infections and cross-contamination.",
        "Assisted in wound closure processes, ensuring sutures, staples, and adhesive agents were readily available and applied correctly.",
        "Operated and maintained surgical equipment, such as electrocautery devices, ensuring functionality and safety.",
        "Diligently set up surgical suites, positioning equipment, drapes, and tools in alignment with surgeon preferences and procedural requirements.",
        "Skillfully draped patients in a sterile manner, ensuring only the operative site was exposed.",
        "Properly collected, labeled, and preserved surgical specimens for laboratory analysis, ensuring accurate tracking and documentation.",
        "Maintained meticulous accounting of sharps, ensuring all needles, blades, and other sharps were accounted for at the end of each procedure.",
        "Adhered to strict sterilization protocols, utilizing autoclaves and other sterilization equipment effectively.",
        "Collaborated with nursing staff to conduct surgical counts, ensuring no items were inadvertently left within patients.",
        "Managed post-operative cleanup, ensuring instruments were properly cleaned, sterilized, and stored.",
        "Quickly addressed and resolved any intraoperative equipment or instrument issues, minimizing procedure delays.",
        "Participated in inventory checks, ensuring necessary surgical supplies were always in stock and properly stored.",
        "Demonstrated quick responsiveness in emergent surgical situations, providing swift assistance and maintaining a calm demeanor.",
        "Showcased adaptability in assisting with a wide range of surgical specialties, from orthopedics to general surgery.",
        "Stayed updated with advancements in surgical techniques, tools, and equipment to enhance operational efficiency.",
        "Worked seamlessly with surgeons, anesthesiologists, and nurses, fostering a collaborative environment for patient safety and care.",
        "Ensured rigorous and accurate documentation of operative procedures, instrument usage, and any intraoperative incidents.",
        "Assisted in safely positioning patients for surgeries, ensuring optimal access to the operative site and patient comfort.",],

    "Respiratory Therapist (rrt)": ["Managed and optimized settings on mechanical ventilators, ensuring patients received adequate respiratory support.",
        "Maintained patient airways through proper suctioning techniques and ensured patency of tracheostomy tubes and endotracheal tubes.",
        "Administered bronchodilator medications to improve airflow in patients with obstructive lung diseases.",
        "Skillfully obtained arterial blood samples and interpreted arterial blood gas results to guide respiratory treatments.",
        "Delivered high-flow oxygen therapy to patients in respiratory distress, adjusting settings based on individual needs.",
        "Set up and managed patients on Bipap and CPAP machines, especially those with sleep apnea or acute pulmonary edema.",
        "Conducted pulmonary function tests to evaluate lung capacity and determine the severity of pulmonary impairment.",
        "Actively participated in code blue scenarios, providing essential respiratory interventions in life-threatening situations.",
        "Administered prescribed aerosol treatments using nebulizers to ensure drug delivery to the lower airways.",
        "Adhered to strict disinfection and sterilization protocols for respiratory equipment, preventing cross-contamination.",
        "Performed sputum induction procedures when necessary to obtain samples for diagnostic testing.",
        "Administered chest physiotherapy to help patients clear secretions and improve lung function.",
        "Maintained readiness for emergency situations, ensuring quick access to vital respiratory equipment and medications.",
        "Utilized devices like the Acapella or Flutter valve to aid patients in clearing mucus from airways.",
        "Conducted detailed respiratory assessments, noting breath sounds, respiratory rate, and signs of distress.",
        "Followed evidence-based protocols for conditions like asthma, COPD, and acute respiratory distress syndrome (ARDS).",
        "Participated in regular respiratory emergency drills to maintain skills and ensure quick response times.",
        "Employed interventions like incentive spirometry to promote lung expansion post-operatively or during prolonged bed rest.",
    ],


    "Dialysis": ["Initiated and terminated hemodialysis treatments, ensuring patient safety and treatment efficacy.",
        "Assessed and managed various vascular access points, including arteriovenous fistulas, grafts, and central venous catheters.",
        "Monitored and adjusted fluid removal rates based on patient's clinical status and physician's orders, preventing hypotensive episodes.",
        "Administered medications during dialysis treatments, such as anticoagulants and antihypertensives, monitoring for potential side effects.",
        "Continuously evaluated patients for electrolyte imbalances and intervened with appropriate adjustments to the dialysate or medications.",
        "Prepared and administered blood transfusions when necessary, monitoring for transfusion reactions.",
        "Regularly calibrated dialysis machines and equipment to ensure optimal treatment efficiency and patient safety.",
        "Reacted swiftly to emergencies, including blood loss, air embolisms, or allergic reactions, ensuring timely interventions.",
        "Worked closely with nephrologists, intensivists, and other medical professionals, ensuring a coordinated approach to patient care.",
        "Adhered to strict protocols for dialyzer reuse, including cleaning, testing, and proper storage.",
        "Managed acute peritoneal dialysis procedures, including catheter insertion site care and exchange procedures.",
        "Meticulously recorded dialysis treatment details, patient responses, and outcomes in electronic health records.",
        "Managed and monitored patients on CRRT in critical care settings, optimizing solute removal and hemodynamic stability.",
        "Engaged in regular quality assurance and improvement activities, ensuring compliance with dialysis standards and best practices.",
        "Upheld rigorous infection control measures during dialysis treatments, including aseptic technique and proper disinfection protocols.",
        "Diagnosed and resolved machine or equipment issues, minimizing treatment interruptions.",
        "Prepared and tested dialysate solutions, ensuring appropriate concentrations for individual patient needs.",
        "Evaluated patients for signs of malnutrition or fluid overload, collaborating with dietitians for intervention.",
        "Kept a vigilant eye on patients with cardiovascular complications, ensuring stability throughout the dialysis process.",
        "Engaged in regular training, certifications, and seminars to stay updated with advancements in the dialysis field."],

    'Psych': ["Deployed rapid response to acute behavioral disturbances, ensuring patient and staff safety through effective de-escalation techniques.",
        "Administered psychotropic medications, vigilantly monitoring for side effects and ensuring therapeutic levels.",
        "Performed comprehensive assessments for suicide risk, implementing safety protocols and closely monitoring high-risk individuals.",
        "Conducted regular behavioral observations, documenting changes or trends that might indicate a shift in the patient's mental status.",
        "Maintained a safe, structured, and therapeutic milieu for patients with various psychiatric disorders.",
        "Assisted in facilitating group therapy sessions, promoting productive discourse and patient participation.",
        "Worked with patients facing substance abuse issues, recognizing withdrawal symptoms and managing detox protocols.",
        "Applied restraints or seclusion when absolutely necessary, following strict guidelines and always prioritizing patient rights and dignity.",
        "Conducted hourly safety rounds, ensuring the environment remains hazard-free and patients are secure.",
        "Developed individualized care plans in collaboration with psychiatrists, social workers, and therapists, ensuring a holistic approach to patient care.",
        "Regularly performed mental status exams, capturing vital information about a patient's cognitive and emotional state.",
        "Maintained precise and timely electronic health record documentation, detailing patient behaviors, interventions, and responses.",
        "Employed therapeutic communication techniques to build trust, extract critical information, and guide patients towards self-awareness and coping strategies.",
        "Conducted screenings for individuals admitted for non-psychiatric reasons, identifying underlying behavioral health concerns.",
        "Demonstrated proficiency in caring for patients with mood disorders, including major depressive disorder, bipolar disorder, and related conditions.",
        "Administered emergency sedative medications under strict protocols, ensuring patient safety and proper dosing.",
        "Managed cases involving patients under protective custody, liaising with law enforcement and legal representatives as required.",
        "Collaborated in discharge planning processes, ensuring patients transition to appropriate follow-up care or facilities.",
        "Upheld patients' legal rights, including commitment procedures and patient confidentiality.",
        "Actively participated in daily team huddles, contributing to patient care discussions and strategy formulation."],

    "Burn Unit": [
        "Conducted initial and ongoing assessments of burn injuries, evaluating the extent and severity to guide treatment plans.",
        "Administered specialized burn wound care, including debridement, dressing changes, and application of topical agents.",
        "Monitored vital signs and systemic responses to burn injuries, including fluid balance and electrolyte levels.",
        "Administered pain management medications, including opioids and non-opioids, with a focus on pain scales specific to burn patients.",
        "Provided psychosocial support to patients and families, addressing the emotional trauma associated with burn injuries.",
        "Managed fluid resuscitation protocols to prevent hypovolemic shock, utilizing tools like the Parkland formula.",
        "Collaborated with a multidisciplinary team consisting of surgeons, physical therapists, nutritionists, and psychologists to provide comprehensive care.",
        "Operated and maintained specialized equipment such as hydrotherapy tanks, wound vac systems, and skin grafting tools.",
        "Coordinated with surgical teams for procedures like skin grafting, wound debridement, and escharotomies.",
        "Observed for complications like infection, sepsis, or hypertrophic scarring and alerted medical staff for immediate intervention.",
        "Educated patients and families on burn care, prevention, and the recovery process, including skin care and pressure garments.",
        "Adhered to strict infection control measures, given the increased susceptibility of burn patients to bacterial infections.",
        "Facilitated physical and occupational therapy sessions, aiding in patient mobility and functional rehabilitation.",
        "Documented patient progress, interventions, and outcomes meticulously in electronic health record systems.",
        "Prepared patients for discharge or transition to rehabilitation facilities, ensuring continuity of care.",
        "Participated in burn research or clinical trials, contributing to advancements in burn care protocols.",
        "Acted swiftly in emergency situations, such as airway compromise or sepsis, to stabilize patients.",
        "Monitored nutritional intake closely, often coordinating with dieticians for specialized nutritional support.",
        "Managed care for patients with associated injuries like smoke inhalation, fractures, or traumatic injuries.",
        "Engaged in ongoing professional development to stay updated with the latest best practices and technologies in burn care."
    ],

    "Cardiac ICU": [
        "Conducted initial and ongoing cardiac assessments, evaluating hemodynamic status, heart rhythms, and other vital signs to determine treatment interventions.",
        "Administered specialized cardiac medications such as antiarrhythmics, inotropes, and antihypertensives, closely monitoring their effects.",
        "Managed mechanical ventilators for patients with compromised respiratory status, ensuring appropriate ventilator settings and monitoring for any changes.",
        "Inserted and managed arterial lines, central lines, and other invasive monitoring devices to obtain real-time data on patients' conditions.",
        "Performed titration of vasoactive medications based on hemodynamic parameters, consulting with physicians for optimal management.",
        "Collaborated closely with cardiologists, surgeons, and other specialists in the multidisciplinary care of patients with critical cardiac conditions.",
        "Operated and interpreted data from specialized equipment like intra-aortic balloon pumps (IABP) or ventricular assist devices (VADs).",
        "Prepared patients for and assisted in procedures like cardiac catheterizations, cardioversions, and other emergent interventions.",
        "Coordinated post-operative care for patients undergoing cardiac surgery, such as coronary artery bypass grafting (CABG) or valve replacements.",
        "Monitored for complications like arrhythmias, myocardial infarctions, and other cardiac emergencies, initiating prompt interventions.",
        "Provided education to patients and families about cardiac conditions, medications, and lifestyle modifications for better cardiac health.",
        "Implemented measures to prevent complications like deep vein thrombosis (DVT), pulmonary embolism, and hospital-acquired infections.",
        "Maintained meticulous documentation in electronic health records, including changes in patient status, administered treatments, and test results.",
        "Managed fluid and electrolyte imbalances, particularly in patients with heart failure or renal complications.",
        "Conducted rounds with interdisciplinary healthcare teams to evaluate patient status and plan future care.",
        "Responded swiftly and effectively in cardiac emergencies, performing life-saving measures such as Advanced Cardiac Life Support (ACLS).",
        "Participated in bedside teaching rounds, academic discussions, and quality improvement projects to enhance patient care and unit performance.",
        "Facilitated family conferences to discuss treatment options, patient prognosis, and end-of-life care when applicable.",
        "Maintained currency in professional development, including certifications in ACLS, Basic Life Support (BLS), and other relevant training.",
        "Monitored and adjusted nutritional support for critically ill cardiac patients, coordinating with dieticians and physicians."
    ],

    "Critical Care": [
        "Performed complex assessments and interventions for critically ill patients, including those with multi-system organ failure.",
        "Managed ventilator settings and performed frequent respiratory assessments to optimize oxygenation and ventilation.",
        "Interpreted continuous hemodynamic monitoring to guide fluid resuscitation and vasoactive medication management.",
        "Collaborated with multiple specialists to coordinate complex care and treatments, such as renal replacement therapy or extracorporeal membrane oxygenation (ECMO).",
        "Administered high-risk medications and continuously monitored for side effects or adverse reactions.",
        "Responded to life-threatening situations with immediate interventions, including code blue procedures and rapid sequence intubation.",
        "Participated in daily interdisciplinary rounds to discuss patient care plans and goals.",
        "Coordinated diagnostic tests and procedures, such as bronchoscopies, lumbar punctures, or surgical interventions.",
        "Provided emotional support and education to family members during critical illness and end-of-life situations.",
        "Documented all aspects of care meticulously in electronic medical records.",
        "Initiated and managed sepsis protocols, including timely antibiotic administration and source control measures.",
        "Managed care for post-operative patients, monitoring for complications such as hemorrhage, infection, or graft failure.",
        "Evaluated and adjusted pain and sedation levels, employing various assessment scales and pharmacological interventions.",
        "Engaged in continuous learning, including in-service education and certification renewals like Advanced Cardiovascular Life Support (ACLS).",
        "Conducted neuro checks for patients with traumatic brain injuries or strokes, including Glasgow Coma Scale and pupil assessments.",
        "Participated in quality improvement initiatives aimed at improving patient outcomes and reducing healthcare-associated infections.",
        "Handled a diverse patient population, including those with trauma, burns, cardiac conditions, and infectious diseases.",
        "Utilized advanced equipment like arterial blood gas analyzers, infusion pumps, and defibrillators.",
        "Collaborated with case managers and social workers for discharge planning and patient education.",
        "Managed end-of-life care, including palliative sedation, family counseling, and ethical considerations."
    ],

    "ER Trauma": [
        "Conducted rapid assessments of trauma patients, prioritizing life-threatening conditions and initiating immediate interventions.",
        "Managed the stabilization of patients with multiple traumas, including head injuries, fractures, and internal bleeding.",
        "Administered emergency medications, fluids, and blood products as indicated by patient condition.",
        "Collaborated with trauma surgeons, emergency physicians, and other specialists in the resuscitation and stabilization of critically injured patients.",
        "Coordinated and assisted in emergent procedures like intubation, chest tube insertion, and surgical interventions.",
        "Monitored continuous vital signs and laboratory results, adjusting treatments as needed.",
        "Participated in trauma team debriefings and quality improvement discussions to improve patient care.",
        "Educated family members about the patient’s condition, treatment plan, and what to expect in the ER setting.",
        "Operated emergency department equipment like defibrillators, ventilators, and intraosseous drills.",
        "Documented all care provided, including procedures performed, medications administered, and patient responses.",
        "Managed mass casualty incidents, triaging patients and allocating resources effectively.",
        "Assisted in the transfer of stabilized patients to appropriate care units or facilities.",
        "Acted swiftly in life-threatening situations like cardiac arrest, anaphylaxis, or severe sepsis.",
        "Facilitated the care of sexual assault victims, working closely with forensic nurses and law enforcement agencies.",
        "Provided wound care, including suturing, dressing changes, and monitoring for signs of infection.",
        "Engaged in continuous learning and professional development, including trauma certifications like Advanced Trauma Life Support (ATLS).",
        "Participated in community outreach programs to promote injury prevention and public health.",
        "Maintained a stocked and ready trauma bay, ensuring quick access to essential supplies and equipment.",
        "Adhered to strict infection control protocols, particularly during invasive procedures or when managing open wounds.",
        "Managed end-of-life care in the ER, including discussions about Do Not Resuscitate (DNR) orders and palliative measures."
    ],

    "Family Nurse Practitioner": [
        "Conducted comprehensive assessments for patients of all ages, from pediatrics to geriatrics.",
        "Diagnosed and managed both acute and chronic medical conditions, collaborating with other healthcare providers as needed.",
        "Prescribed medications and monitored for effectiveness, side effects, and potential drug interactions.",
        "Ordered and interpreted diagnostic tests, such as blood work, imaging studies, and EKGs.",
        "Provided preventive care, including vaccinations, health screenings, and lifestyle counseling.",
        "Managed women’s health, including contraceptive counseling, prenatal care, and menopausal support.",
        "Developed and implemented individualized care plans, tailored to meet the needs and goals of each patient.",
        "Referred patients to specialists for conditions requiring further evaluation or treatment.",
        "Educated patients and their families on disease management, medication adherence, and self-care techniques.",
        "Conducted telehealth visits for remote consultation and follow-up.",
        "Documented all clinical encounters meticulously in electronic health records.",
        "Participated in interdisciplinary team meetings for complex cases and care coordination.",
        "Provided acute care for minor injuries, including wound care, splinting, and suturing.",
        "Administered in-office procedures like joint injections, skin biopsies, or minor surgeries.",
        "Reviewed and managed patient messages and queries, providing timely and appropriate responses.",
        "Engaged in continuous professional development and education to stay updated on current best practices.",
        "Oversaw the management of chronic diseases like diabetes, hypertension, and asthma.",
        "Collaborated with case managers and social workers for comprehensive care planning.",
        "Managed end-of-life and palliative care discussions, offering emotional and medical support.",
        "Maintained an active state license, DEA certification, and fulfilled state-mandated continuing education requirements."
    ],

    "Geriatrics": [
        "Assessed and managed multiple comorbidities commonly found in the elderly population.",
        "Conducted cognitive assessments to screen for conditions like dementia or delirium.",
        "Administered medications, carefully considering age-related changes in pharmacokinetics and pharmacodynamics.",
        "Worked closely with families and caregivers to provide education and support for managing elderly care.",
        "Monitored and managed chronic pain, considering both pharmacologic and non-pharmacologic interventions.",
        "Participated in falls risk assessments and implemented preventive measures.",
        "Collaborated with interdisciplinary teams for comprehensive geriatric assessments, including physical therapy, occupational therapy, and social work.",
        "Assisted in advance care planning, including discussions about living wills, durable power of attorney, and end-of-life care.",
        "Provided support for age-related mental health conditions like depression, anxiety, and insomnia.",
        "Managed wound care, focusing on prevention and treatment of pressure ulcers and infections.",
        "Coordinated transitions of care, whether from hospital to home, or to long-term care facilities.",
        "Implemented measures to manage and prevent polypharmacy and adverse drug reactions.",
        "Educated patients and caregivers on the safe use of mobility aids like walkers, canes, and wheelchairs.",
        "Conducted regular medication reviews to optimize regimens and reduce unnecessary prescriptions.",
        "Engaged in palliative and end-of-life care, managing symptoms and providing emotional support.",
        "Performed routine screenings for malnutrition and implemented dietary interventions as needed.",
        "Managed urinary and fecal incontinence with appropriate interventions and products.",
        "Monitored for common geriatric syndromes like frailty, syncope, and dizziness.",
        "Participated in ethical discussions related to capacity, consent, and guardianship.",
        "Engaged in continuous education on geriatric best practices, including maintaining relevant certifications like Certified Gerontological Nurse (CGN)."
    ],

    "Home Health": [
        "Conducted in-home assessments to determine patients’ medical, functional, and psychosocial needs.",
        "Developed individualized care plans in collaboration with physicians and family members.",
        "Administered medications, injections, and performed wound care as per physician orders.",
        "Monitored chronic conditions like diabetes, COPD, and heart failure, adjusting care plans as necessary.",
        "Provided education on medication management, safety precautions, and symptom monitoring.",
        "Coordinated with other healthcare providers, such as occupational therapists, speech therapists, and dietitians for comprehensive care.",
        "Evaluated the home environment for safety hazards and suggested modifications.",
        "Performed physical exams and collected samples for lab tests as required.",
        "Assisted with activities of daily living (ADLs), including bathing, feeding, and mobility.",
        "Documented all care activities and observations in electronic health records for billing and continuity of care.",
        "Managed acute symptoms or conditions, escalating to emergency services when necessary.",
        "Conducted regular telehealth check-ins for remote monitoring and consultation.",
        "Collaborated with pharmacists to ensure medication reconciliation and adherence.",
        "Provided end-of-life care, supporting both patients and their families through the process.",
        "Assisted with the setup and management of medical equipment like oxygen concentrators, hospital beds, and infusion pumps.",
        "Educated families and caregivers on how to support patients’ healthcare needs effectively.",
        "Managed patient schedules and ensured timely visits based on clinical necessity.",
        "Adhered to infection control practices, including the use of personal protective equipment (PPE) to prevent cross-contamination.",
        "Participated in agency meetings and quality improvement initiatives to improve home health care standards.",
        "Maintained up-to-date licensure, certification, and participated in ongoing training programs."
    ],

    "Hospice": [
        "Provided compassionate end-of-life care to patients with terminal illnesses.",
        "Administered pain and symptom management medications to optimize comfort.",
        "Collaborated with interdisciplinary care teams, including physicians, social workers, and chaplains.",
        "Conducted regular patient assessments, focusing on pain, emotional well-being, and spiritual needs.",
        "Educated families on how to care for their loved ones during the end-of-life process.",
        "Coordinated with pharmacists to manage medication regimens for symptom control.",
        "Implemented emotional and psychological interventions, such as active listening and providing emotional support.",
        "Documented care activities and medication administration accurately in electronic health records.",
        "Assisted with activities of daily living, including hygiene, feeding, and positioning.",
        "Facilitated conversations around advanced care planning and end-of-life wishes.",
        "Provided bereavement support to families before and after the loss of a loved one.",
        "Engaged in crisis intervention, managing acute symptoms that require immediate attention.",
        "Participated in ethical discussions related to end-of-life care, such as withdrawing treatment.",
        "Maintained a peaceful and comforting environment, including lighting, music, and aromatherapy, where appropriate.",
        "Adhered to infection control protocols, particularly when managing wounds or catheters.",
        "Managed respite care to provide short-term relief for primary caregivers.",
        "Monitored for signs of impending death to prepare families emotionally and logistically.",
        "Actively participated in staff meetings and continuing education opportunities related to palliative and hospice care.",
        "Maintained compliance with federal and state hospice regulations and guidelines.",
        "Collaborated with community resources to provide additional support services, such as volunteers or donated goods."
    ],

    "Medical Oncology": [
        "Administered chemotherapy and other anti-cancer medications, following strict safety protocols.",
        "Monitored patients for adverse effects during and after treatment, initiating appropriate interventions.",
        "Conducted comprehensive assessments focusing on symptoms, pain levels, and overall well-being.",
        "Provided education on managing side effects, medication administration, and lifestyle modifications.",
        "Collaborated with oncologists to develop and implement individualized treatment plans.",
        "Assisted in procedures such as bone marrow aspirations and biopsies.",
        "Coordinated with other healthcare providers for supportive treatments, such as radiation or surgical oncology.",
        "Participated in multidisciplinary tumor boards to discuss complex cancer cases.",
        "Managed central lines, ports, and other venous access devices.",
        "Delivered psychosocial support to patients and their families, including coping strategies and mental health resources.",
        "Documented all clinical interventions and patient responses meticulously in electronic health records.",
        "Adhered to infection control standards, especially critical given the immunocompromised status of many patients.",
        "Monitored and managed complications such as neutropenia, anemia, and thrombocytopenia.",
        "Provided genetic and preventive education for patients at high risk of specific cancer types.",
        "Handled biohazardous materials safely, including disposing of chemotherapy waste.",
        "Actively engaged in research protocols and clinical trials when appropriate.",
        "Coordinated palliative care services for patients with advanced-stage cancers.",
        "Assisted patients in navigating healthcare resources, insurance, and financial aid options.",
        "Maintained an up-to-date knowledge base through continuous education and professional development.",
        "Managed survivorship care, focusing on long-term monitoring and preventive measures."
    ],

    "Neonatal": [
        "Provided specialized care for newborns, including premature infants and those with congenital conditions.",
        "Monitored vital signs, respiratory status, and feeding patterns closely.",
        "Administered medications and vaccinations per physician orders.",
        "Operated and managed neonatal life-supporting equipment, such as ventilators and incubators.",
        "Assessed and managed neonatal jaundice, coordinating phototherapy as needed.",
        "Educated parents on infant care, including feeding, hygiene, and signs of illness.",
        "Collaborated with neonatologists and other specialists to implement treatment plans.",
        "Conducted developmental screenings and neurological assessments.",
        "Coordinated with lactation consultants to support breastfeeding mothers.",
        "Handled emergency situations such as neonatal resuscitation or airway management.",
        "Documented all clinical activities, including growth charts and medication administration records.",
        "Provided family-centered care, involving parents in the caregiving process whenever possible.",
        "Participated in multidisciplinary rounds to discuss complex cases and care planning.",
        "Managed central lines and umbilical catheters, ensuring sterile technique.",
        "Performed minor procedures like blood draws, lumbar punctures, and intubations as needed.",
        "Participated in the transfer of critically ill infants to specialized units or facilities.",
        "Engaged in parent support groups, offering education and emotional support.",
        "Adhered to infection control protocols to prevent transmission of pathogens in the neonatal unit.",
        "Supported the emotional needs of families facing neonatal loss or complications.",
        "Participated in continuing education and professional development activities specific to neonatal nursing."
    ],

    "Nephrology": [
        "Provided specialized care for patients with acute or chronic kidney diseases.",
        "Managed dialysis treatments, including hemodialysis and peritoneal dialysis.",
        "Conducted comprehensive assessments focusing on kidney function, fluid balance, and electrolytes.",
        "Administered medications related to kidney diseases and dialysis treatments.",
        "Educated patients and families on managing kidney diseases, dietary restrictions, and medication compliance.",
        "Monitored patients for complications such as hypertension, electrolyte imbalance, and infection.",
        "Collaborated with nephrologists to develop and implement individualized treatment plans.",
        "Performed vascular access care, including catheter and fistula management.",
        "Documented all clinical interventions, lab results, and patient responses meticulously in electronic health records.",
        "Managed fluid and electrolyte balance meticulously, adjusting treatments as needed.",
        "Coordinated kidney transplant care, from pre-transplant evaluation to post-transplant monitoring.",
        "Adhered to strict infection control protocols, particularly in dialysis settings.",
        "Participated in multidisciplinary rounds to discuss complex cases and care planning.",
        "Provided psychosocial support to patients dealing with the chronic nature of kidney disease.",
        "Handled emergency situations like hyperkalemia or fluid overload efficiently.",
        "Assisted with procedures like kidney biopsies and placement of dialysis catheters.",
        "Engaged in continuous education to stay updated with nephrology best practices.",
        "Supported patients in adhering to strict dietary and fluid restrictions.",
        "Collaborated with social workers and dietitians for comprehensive patient care.",
        "Actively participated in quality improvement projects specific to nephrology care."
    ],

    "OB/GYN": [
        "Provided antepartum, intrapartum, and postpartum care for expectant mothers.",
        "Monitored fetal heart rate and maternal vital signs during labor and delivery.",
        "Administered medications to induce or augment labor, as ordered by the physician.",
        "Educated patients on family planning, prenatal care, and childbirth options.",
        "Assisted with gynecological exams and procedures like pap smears or biopsies.",
        "Managed emergencies like eclampsia, hemorrhage, or fetal distress swiftly and efficiently.",
        "Supported patients during Cesarean sections, including preoperative preparation and postoperative recovery.",
        "Provided lactation support and newborn care education to new mothers.",
        "Collaborated closely with obstetricians, midwives, and other interdisciplinary team members.",
        "Handled patient documentation, including labor progress and treatment interventions.",
        "Counseled women on menstrual health, contraception, and sexual health.",
        "Participated in community outreach programs focused on women's health.",
        "Facilitated childbirth classes and prenatal workshops.",
        "Adhered to infection control protocols, especially during labor and surgical procedures.",
        "Managed care for high-risk pregnancies, including those with gestational diabetes or preeclampsia.",
        "Performed newborn assessments and initial care, including Apgar scoring.",
        "Engaged in professional development activities specific to obstetric and gynecological nursing.",
        "Coordinated with pediatric teams for the care of newborns requiring special attention.",
        "Advocated for patient preferences, such as birth plans or pain management options.",
        "Assisted with infertility treatments and reproductive health services."
    ],

    "Occupational Health": [
        "Conducted pre-employment screenings and periodic health evaluations for employees.",
        "Managed cases of workplace injuries, coordinating care and facilitating returns to work.",
        "Educated employees on occupational safety, ergonomics, and wellness programs.",
        "Administered vaccinations and other preventive healthcare services.",
        "Conducted workplace assessments to identify potential health risks and hazards.",
        "Collaborated with employers to develop and implement workplace health policies.",
        "Provided counseling and referrals for issues like stress, substance abuse, or chronic conditions.",
        "Managed worker's compensation cases, liaising between employees, employers, and healthcare providers.",
        "Monitored and managed outbreaks of infectious diseases within the workplace.",
        "Provided emergency care for acute workplace injuries or illnesses.",
        "Documented all clinical interventions and worker health records in line with legal requirements.",
        "Conducted fit-for-duty evaluations and clearance for specialized job roles.",
        "Coordinated with public health agencies for regulatory compliance and reporting.",
        "Implemented and managed wellness initiatives, such as fitness programs or smoking cessation clinics.",
        "Administered drug tests and other screening procedures as required.",
        "Monitored the health status of employees exposed to hazardous substances.",
        "Engaged in professional development and continuing education in occupational health nursing.",
        "Served as a liaison between management and employees on health-related matters.",
        "Provided travel health consultations and vaccinations for employees traveling internationally.",
        "Evaluated and recommended ergonomic interventions to prevent workplace injuries."
    ],

    "Oncology": [
        "Provided specialized nursing care to patients with cancer at various stages.",
        "Administered chemotherapy and other cancer treatments under strict protocols.",
        "Conducted assessments focusing on pain management, nutritional status, and treatment side effects.",
        "Educated patients and families about cancer diagnosis, treatment options, and self-care.",
        "Managed symptoms and side effects of cancer treatments, such as nausea, fatigue, and skin issues.",
        "Collaborated closely with oncologists and other healthcare professionals in treatment planning.",
        "Monitored patients for complications, including infections, anemia, and neutropenia.",
        "Supported patients emotionally, providing resources for psychological and spiritual care.",
        "Administered and managed medications, including analgesics, antiemetics, and targeted therapies.",
        "Documented all clinical activities and patient responses in electronic health records.",
        "Facilitated communication between the patient and the healthcare team, advocating for patient needs.",
        "Coordinated palliative and end-of-life care, focusing on patient comfort and quality of life.",
        "Assisted with diagnostic procedures like biopsies and lumbar punctures.",
        "Engaged in ongoing professional development in the field of oncology nursing.",
        "Managed intravenous lines, central venous access devices, and ports.",
        "Conducted patient and community education on cancer prevention and early detection.",
        "Participated in research activities related to oncology nursing practice.",
        "Handled emergency situations, such as allergic reactions to medications or treatment complications.",
        "Adhered to stringent infection control protocols, particularly for immunocompromised patients.",
        "Collaborated with social workers and counselors to provide holistic patient care."
    ],

    "Orthopedics": [
        "Provided nursing care to patients with musculoskeletal issues, including fractures, joint replacements, and spinal injuries.",
        "Managed postoperative care for patients undergoing orthopedic surgeries.",
        "Conducted comprehensive assessments, including pain levels, wound healing, and mobility.",
        "Administered medications, such as pain relievers, antibiotics, and anticoagulants.",
        "Educated patients on post-surgical care, physical therapy exercises, and mobility aids.",
        "Assisted in diagnostic and therapeutic procedures like X-rays, MRI scans, and joint aspirations.",
        "Collaborated with orthopedic surgeons, physical therapists, and other interdisciplinary team members.",
        "Monitored patients for complications like infection, blood clots, and delayed healing.",
        "Coordinated discharge planning, ensuring that patients have the necessary resources for recovery.",
        "Documented all patient care activities and outcomes in electronic health records.",
        "Handled wound care, including dressing changes, pin site care, and surgical site monitoring.",
        "Managed casts, braces, and other orthopedic devices, ensuring proper fit and function.",
        "Provided emergency care for acute musculoskeletal injuries, such as fractures or dislocations.",
        "Engaged in professional development to stay updated on orthopedic best practices.",
        "Participated in quality improvement initiatives related to orthopedic care.",
        "Coordinated with case managers for long-term care planning and follow-up.",
        "Conducted preoperative preparation, including patient education and surgical site marking.",
        "Adhered to strict infection control protocols to prevent postoperative complications.",
        "Assisted patients with mobility, using safe patient handling techniques.",
        "Monitored and managed pain effectively, adjusting interventions as necessary."
    ],

    "Pediatrics": [
        "Provided nursing care to children from infancy through adolescence.",
        "Conducted comprehensive developmental assessments, including growth metrics and immunization status.",
        "Administered pediatric medications, ensuring accurate dosages based on age and weight.",
        "Educated families on child health, nutrition, and safety measures.",
        "Managed acute conditions like asthma, fever, and infections in a pediatric setting.",
        "Assisted in pediatric surgeries, handling preoperative and postoperative care.",
        "Collaborated with pediatricians, family physicians, and other interdisciplinary team members.",
        "Provided emotional support to children and families, utilizing age-appropriate communication techniques.",
        "Handled emergency situations, such as respiratory distress or seizure episodes.",
        "Documented all clinical interventions and patient responses in electronic health records.",
        "Administered vaccinations as per pediatric immunization guidelines.",
        "Participated in child health outreach and community education programs.",
        "Provided specialized care for children with chronic conditions like diabetes, cystic fibrosis, or congenital disorders.",
        "Managed intravenous lines and central venous access in pediatric patients.",
        "Adhered to strict infection control measures, especially for immunocompromised children.",
        "Engaged in professional development specific to pediatric nursing.",
        "Coordinated with educational institutions for managing child health in schools.",
        "Conducted newborn assessments, including screenings and initial care.",
        "Advocated for child safety and abuse prevention, reporting any suspicions as mandated.",
        "Coordinated with social services for family support and child healthcare needs."
    ],

    "Postpartum": [
        "Provided nursing care to mothers and newborns immediately following childbirth.",
        "Monitored vital signs, lochia, and perineal care to ensure maternal well-being.",
        "Administered medications, including pain relief and uterine contraction agents.",
        "Educated families on infant care, breastfeeding, and postpartum recovery.",
        "Assisted mothers with breastfeeding and latch-on techniques.",
        "Managed newborn assessments, including weight checks and jaundice monitoring.",
        "Identified and intervened in postpartum complications, such as hemorrhage or infection.",
        "Collaborated with obstetricians and pediatricians for comprehensive family care.",
        "Conducted screenings for postpartum depression and provided emotional support.",
        "Documented all clinical activities and patient responses in electronic health records.",
        "Facilitated early bonding activities between the newborn and family members.",
        "Managed immunizations and screenings for the newborn, such as heel pricks.",
        "Assisted in newborn care procedures like circumcision or umbilical cord care.",
        "Engaged in professional development activities in maternal-child nursing.",
        "Coordinated discharge planning, including follow-up appointments and home care.",
        "Implemented safety protocols for mother and baby, including identification bands and rooming-in.",
        "Provided patient education on contraception and family planning.",
        "Participated in quality improvement initiatives for postpartum care.",
        "Handled emergency situations, such as neonatal resuscitation or maternal complications.",
        "Administered Rho(D) immune globulin to Rh-negative mothers when indicated."
    ],

    "Surgical Oncology": [
        "Provided specialized care for patients undergoing cancer-related surgeries.",
        "Administered chemotherapy, targeted therapy, and other oncologic medications.",
        "Conducted preoperative assessments, including overall health and cancer staging.",
        "Educated patients and families about surgical procedures, risks, and postoperative care.",
        "Collaborated with oncologists, surgeons, and other interdisciplinary team members.",
        "Managed postoperative complications like infection, bleeding, or delayed healing.",
        "Monitored and reported vital signs, laboratory results, and symptomatology.",
        "Documented all clinical activities and patient outcomes in electronic health records.",
        "Participated in tumor board meetings and contributed to care planning.",
        "Coordinated with palliative care teams for pain and symptom management.",
        "Managed intravenous lines and ensured proper hydration and nutrition.",
        "Facilitated referrals for radiation therapy or other adjunctive treatments.",
        "Provided psychosocial support and resources for coping with cancer.",
        "Adhered to strict infection control protocols to protect immunocompromised patients.",
        "Participated in research trials and quality improvement initiatives related to oncology.",
        "Ensured that surgical consent and related documentation are in order.",
        "Provided postoperative wound care and managed surgical drains.",
        "Conducted patient teaching on ostomy care, as applicable.",
        "Followed up on pathology reports and communicated results to patients and families.",
        "Engaged in professional development specific to surgical oncology nursing."
    ],

    "Transplant": [
        "Managed pre-transplant evaluations and screenings for organ recipients and donors.",
        "Administered immunosuppressive medications and monitored for side effects.",
        "Educated patients and families on transplant procedures, risks, and lifestyle changes.",
        "Coordinated with organ procurement organizations and surgical teams.",
        "Monitored patients for signs of organ rejection or infection post-transplant.",
        "Assisted in donor and recipient matching, adhering to ethical guidelines.",
        "Documented all clinical activities, transplant metrics, and patient outcomes.",
        "Collaborated with nephrologists, hepatologists, cardiologists, and other specialists.",
        "Managed perioperative care for both donors and recipients.",
        "Provided emotional support during the waiting period and after transplantation.",
        "Ensured strict adherence to antirejection medication regimens.",
        "Participated in interdisciplinary team meetings focused on transplant care.",
        "Managed vascular access sites and devices, such as dialysis catheters.",
        "Engaged in patient advocacy, especially in navigating the transplant system.",
        "Adhered to quality and safety standards in transplant care.",
        "Facilitated rehabilitation and lifestyle adjustments post-transplant.",
        "Conducted post-transplant follow-up, including regular monitoring and labs.",
        "Participated in quality improvement projects related to transplant outcomes.",
        "Engaged in ongoing professional development in the field of transplant nursing.",
        "Managed ethical dilemmas and facilitated ethical consultations as needed."
    ],

    "Urology": [
        "Provided nursing care to patients with genitourinary conditions.",
        "Assisted in diagnostic procedures like cystoscopies and urodynamic testing.",
        "Administered medications for conditions like urinary tract infections or incontinence.",
        "Educated patients on bladder training, catheter care, and sexual health.",
        "Collaborated with urologists and other interdisciplinary team members.",
        "Managed postoperative care for surgeries like prostatectomies or nephrectomies.",
        "Monitored and documented urinary output, and managed urinary catheters.",
        "Facilitated referrals for further evaluation or treatment, such as oncology or nephrology.",
        "Participated in men’s and women’s health initiatives related to urologic conditions.",
        "Managed complications like hematuria, urinary retention, or postoperative infections.",
        "Assisted in ultrasound-guided procedures and biopsies.",
        "Provided preoperative and postoperative education for urologic surgeries.",
        "Documented all clinical activities and patient responses in electronic health records.",
        "Engaged in ongoing professional development in urology nursing.",
        "Adhered to infection control protocols, particularly related to catheter care.",
        "Conducted assessments focusing on renal function, fluid balance, and electrolytes.",
        "Handled sensitive topics like sexual dysfunction or infertility with discretion.",
        "Participated in quality improvement initiatives related to urologic care.",
        "Managed care for patients with urinary diversions like urostomies.",
        "Coordinated with radiology for imaging studies related to urologic conditions."
    ],

    "Wound Care": [
        "Specialized in the assessment, treatment, and management of acute and chronic wounds.",
        "Conducted wound evaluations, including measurements, staging, and infection signs.",
        "Administered advanced wound treatments, such as debridement and wound vac therapy.",
        "Educated patients and caregivers on wound care techniques and materials.",
        "Collaborated with surgeons, infectious disease specialists, and other healthcare providers.",
        "Monitored wound healing progress and adjusted treatment plans accordingly.",
        "Documented wound care procedures, outcomes, and patient responses.",
        "Adhered to strict infection control protocols to prevent cross-contamination.",
        "Managed patient pain during wound care procedures.",
        "Participated in interdisciplinary wound care meetings and case reviews.",
        "Developed care plans aimed at preventing pressure ulcers in at-risk patients.",
        "Ordered wound care supplies and ensured appropriate material availability.",
        "Conducted community education on wound prevention and care.",
        "Engaged in ongoing professional development in the field of wound care.",
        "Evaluated the nutritional status of patients and recommended dietary changes for wound healing.",
        "Managed care for patients with special wound care needs, such as ostomies or fistulas.",
        "Facilitated consultations for surgical interventions, as needed.",
        "Coordinated with home health agencies for continuation of wound care at home.",
        "Participated in research and quality improvement projects related to wound care.",
        "Maintained meticulous documentation for billing and reimbursement of wound care services."
    ],

    "Acute Care": [
        "Provided rapid assessment and treatment to critically ill patients.",
        "Administered intravenous fluids, medications, and treatments.",
        "Conducted regular patient assessments, monitoring vital signs and symptoms.",
        "Collaborated with interdisciplinary teams for effective patient care planning.",
        "Managed ventilators and other life-supporting devices.",
        "Performed advanced nursing procedures such as central line maintenance.",
        "Assisted physicians in diagnostic and therapeutic procedures.",
        "Coordinated discharge planning for patients transitioning to sub-acute settings.",
        "Provided emotional support and education for patients and families.",
        "Documented all nursing care accurately and comprehensively.",
        "Adhered to infection control protocols and safety guidelines.",
        "Participated in departmental quality improvement initiatives.",
        "Educated patients and families on managing acute health conditions.",
        "Monitored labs and diagnostic studies, reporting abnormalities to healthcare team.",
        "Managed patient flow and bed assignments in collaboration with unit manager.",
        "Administered and monitored sedation during procedures.",
        "Engaged in professional development related to acute care nursing.",
        "Handled emergency situations swiftly, initiating rapid response or code as needed.",
        "Maintained updated knowledge of pharmacology and medication administration.",
        "Ensured proper identification and tracking of patient conditions for research and data collection."
    ],

    "Allergy/Immunology": [
        "Performed allergy tests and immunotherapy under physician guidance.",
        "Educated patients and families about allergens, asthma, and related conditions.",
        "Administered and monitored medications such as antihistamines and steroids.",
        "Coordinated with laboratories for specialized immune system testing.",
        "Assisted in the diagnosis and treatment of autoimmune diseases.",
        "Managed anaphylactic reactions and other allergy-related emergencies.",
        "Advised on environmental modifications to reduce allergen exposure.",
        "Documented patient histories, symptoms, and medication regimens.",
        "Collaborated with physicians in developing individualized care plans.",
        "Provided asthma education, including inhaler techniques and peak flow monitoring.",
        "Conducted skin tests and interpreted results for allergen sensitivities.",
        "Coordinated immunization schedules for both pediatric and adult patients.",
        "Adhered to infection control protocols, especially for immunocompromised patients.",
        "Managed chronic conditions like chronic urticaria or immune deficiencies.",
        "Engaged in community outreach programs for allergy and asthma education.",
        "Updated and maintained patient records in electronic health systems.",
        "Ordered supplies and maintained allergy extract inventory.",
        "Engaged in professional development related to allergy and immunology.",
        "Participated in research and data collection related to allergic diseases.",
        "Handled patient scheduling and follow-up appointments for ongoing care."
    ],

    "Ambulatory Care": [
        "Provided care for patients in outpatient settings, including clinics and same-day surgery centers.",
        "Conducted initial assessments and developed individualized care plans.",
        "Administered medications and treatments as prescribed.",
        "Educated patients about managing chronic conditions like diabetes or hypertension.",
        "Performed minor procedures like suturing, wound dressing, and catheter insertion.",
        "Coordinated care with other healthcare providers and made referrals as needed.",
        "Documented all patient interactions, including medical history and treatment plans.",
        "Managed patient flow, scheduling, and administrative tasks.",
        "Provided telehealth services, including remote monitoring and consultation.",
        "Ensured a smooth transition of care between inpatient and outpatient settings.",
        "Monitored patient vital signs and lab results.",
        "Managed emergency situations, providing immediate care or directing to higher-level care.",
        "Promoted preventive care through patient education and screenings.",
        "Conducted pre- and post-operative education for surgical patients.",
        "Participated in quality improvement initiatives and audits.",
        "Administered immunizations and other preventative measures.",
        "Managed chronic wound care and other long-term conditions.",
        "Engaged in community health education and outreach.",
        "Oversaw prescription refills and medication reconciliation.",
        "Ensured compliance with healthcare regulations and safety standards."
    ],

    "Anesthesia": [
        "Administered anesthesia and anesthesia-related care in various settings.",
        "Conducted pre-anesthetic assessments and prepared patients for surgery.",
        "Monitored patients throughout surgical procedures for any anesthesia complications.",
        "Managed patient airways, including intubation and extubation.",
        "Collaborated with anesthesiologists and surgical teams.",
        "Provided post-anesthesia care, including monitoring and symptom management.",
        "Documented all anesthesia activities and patient responses.",
        "Administered intravenous fluids and medications as per protocol.",
        "Managed patient sedation during diagnostic or therapeutic procedures.",
        "Educated patients and families about anesthesia and its risks.",
        "Adjusted anesthesia levels during procedures based on patient needs.",
        "Ensured all anesthesia equipment was properly maintained and calibrated.",
        "Adhered to strict protocols for anesthesia administration and patient safety.",
        "Participated in departmental quality improvement and safety audits.",
        "Engaged in ongoing professional development related to anesthesia care.",
        "Prepared and stocked anesthesia cart with necessary supplies and medications.",
        "Managed emergency situations requiring rapid anesthesia intervention.",
        "Collaborated with post-anesthesia care units for smooth patient transfer.",
        "Maintained updated knowledge of pharmacology, especially anesthesia medications.",
        "Ensured proper documentation for billing and legal requirements."
    ],

    "Bariatric Nursing": [
        "Provided care for patients undergoing weight-loss surgeries or treatments.",
        "Conducted preoperative assessments and education.",
        "Monitored patients postoperatively for complications such as infection or leaks.",
        "Educated patients on lifestyle changes, including diet and exercise.",
        "Administered medications such as analgesics and antiemetics.",
        "Assisted surgeons during bariatric procedures as needed.",
        "Documented patient progress, complications, and outcomes.",
        "Participated in multidisciplinary teams, including dietitians and psychologists.",
        "Managed patient emotional and psychological needs related to weight loss.",
        "Adhered to strict protocols for patient safety and surgical sterilization.",
        "Conducted community education on obesity and weight management.",
        "Engaged in professional development in the field of bariatric nursing.",
        "Managed postoperative nutritional needs, including vitamin supplementation.",
        "Facilitated patient support groups or educational seminars.",
        "Assisted in diagnostic procedures such as endoscopies or ultrasounds.",
        "Managed wound care and incision sites postoperatively.",
        "Conducted long-term follow-ups for surgical outcomes and weight management.",
        "Participated in quality improvement initiatives related to bariatric care.",
        "Ensured all patient documentation is accurate and up-to-date.",
        "Coordinated with primary care providers for long-term weight management plans."
    ],

    "Community Health": [
        "Performed community health assessments and developed intervention plans.",
        "Provided health education to community members on preventive measures and wellness.",
        "Administered vaccines and conducted immunization campaigns.",
        "Conducted outreach programs for vulnerable populations.",
        "Collaborated with local agencies to address public health concerns.",
        "Managed chronic diseases through education and medication management.",
        "Provided maternal and child health services, including family planning.",
        "Conducted screenings for conditions like hypertension and diabetes.",
        "Collected data for epidemiological research and community health analysis.",
        "Assisted in emergency preparedness and disaster response activities.",
        "Offered mental health support and referrals.",
        "Managed infection control and disease prevention in community settings.",
        "Engaged in poverty alleviation and social determinants of health interventions.",
        "Coordinated with schools for health education programs.",
        "Monitored and reported on environmental health risks.",
        "Developed and implemented community health policies.",
        "Provided home visits for patients unable to access healthcare facilities.",
        "Engaged in professional development in public and community health.",
        "Managed databases and utilized GIS mapping for health needs assessment.",
        "Collaborated in grant writing and fundraising for community health projects."
    ],

    "Dermatology": [
        "Assisted in dermatological procedures such as biopsies, laser treatments, and excisions.",
        "Provided education on skincare, including sun protection and acne management.",
        "Administered medications like topical steroids and immunosuppressants.",
        "Managed dermatological conditions such as psoriasis, eczema, and skin cancer.",
        "Conducted skin assessments and documented findings.",
        "Assisted in cosmetic procedures like Botox injections or chemical peels.",
        "Managed wound care for surgical sites or ulcers.",
        "Educated patients on post-procedural care.",
        "Documented patient history and treatment plans.",
        "Collaborated with dermatologists for diagnosis and treatment planning.",
        "Maintained sterile environments for procedures.",
        "Managed patient scheduling and follow-up appointments.",
        "Conducted patch tests for allergies or skin sensitivities.",
        "Participated in dermatological research and data collection.",
        "Provided emotional support for patients with chronic skin conditions.",
        "Engaged in professional development in the field of dermatology nursing.",
        "Managed phototherapy treatments for conditions like psoriasis.",
        "Coordinated referrals to other specialists for systemic issues.",
        "Advised on skin products and lifestyle modifications.",
        "Ensured compliance with healthcare regulations and safety protocols."
    ],

    "Diabetes Management": [
        "Educated patients on diabetes management, including glucose monitoring and insulin administration.",
        "Managed glycemic control through medication adjustments and lifestyle interventions.",
        "Provided dietary advice tailored to diabetes management.",
        "Conducted regular foot and eye exams to prevent complications.",
        "Managed insulin pumps and continuous glucose monitoring devices.",
        "Assisted in the management of diabetes-related complications like neuropathy or retinopathy.",
        "Developed individualized care plans in collaboration with endocrinologists.",
        "Documented patient progress and medication regimens.",
        "Conducted community education programs on diabetes prevention.",
        "Administered medications such as insulin and oral hypoglycemics.",
        "Managed emergency hypoglycemic or hyperglycemic episodes.",
        "Conducted screenings for at-risk populations.",
        "Coordinated care with other healthcare providers for holistic diabetes management.",
        "Participated in diabetes research and data collection.",
        "Advised on the use of diabetes technology and apps for self-management.",
        "Conducted pre- and post-operative care for diabetic patients.",
        "Collaborated in the management of gestational diabetes.",
        "Participated in quality improvement initiatives related to diabetes care.",
        "Engaged in professional development in endocrinology and diabetes nursing.",
        "Managed patient records, ensuring accurate documentation for ongoing care."
    ],

    "Gastroenterology": [
        "Assisted in gastrointestinal procedures like colonoscopies and endoscopies.",
        "Managed patients with gastrointestinal disorders like IBD, IBS, and GERD.",
        "Administered medications like antacids, PPIs, and antiemetics.",
        "Conducted patient education on dietary modifications for GI disorders.",
        "Managed pre- and post-procedure care for patients undergoing GI surgeries.",
        "Documented patient symptoms, history, and treatment plans.",
        "Managed complications like gastrointestinal bleeding or perforations.",
        "Collaborated with gastroenterologists for diagnostic and treatment planning.",
        "Provided emotional support for patients with chronic GI conditions.",
        "Engaged in professional development in gastroenterology nursing.",
        "Managed ostomy care and education.",
        "Conducted liver function tests and other diagnostic studies.",
        "Administered enteral and parenteral nutrition as needed.",
        "Participated in research and data collection in the field of gastroenterology.",
        "Handled patient scheduling and follow-up care.",
        "Maintained infection control standards, particularly during endoscopic procedures.",
        "Managed wound care for post-surgical patients.",
        "Participated in quality improvement initiatives related to GI care.",
        "Advised on lifestyle changes to manage or prevent GI disorders.",
        "Coordinated referrals to other specialists for comprehensive care."
    ],

    "Genetics": [
        "Provided genetic counseling for patients and families with hereditary conditions.",
        "Conducted risk assessments based on family history and genetic testing.",
        "Educated patients on the implications of genetic testing and results.",
        "Managed genetic data and communicated findings to healthcare teams.",
        "Assisted in prenatal genetic screenings and counseling.",
        "Managed patients with genetic disorders like cystic fibrosis or Huntington's disease.",
        "Collaborated with geneticists for diagnosis and treatment planning.",
        "Documented patient histories, genetic test results, and interventions.",
        "Conducted community education on genetic disorders and testing.",
        "Coordinated with laboratories for genetic testing procedures.",
        "Provided emotional support for patients grappling with genetic information.",
        "Engaged in professional development in the field of genetics nursing.",
        "Managed ethical considerations related to genetic data and privacy.",
        "Participated in research related to genetics and hereditary diseases.",
        "Advised on reproductive options for couples with genetic risks.",
        "Managed pharmacogenomic data for personalized medication regimens.",
        "Coordinated care with other specialists for comprehensive genetic healthcare.",
        "Managed documentation for legal and ethical compliance in genetic counseling.",
        "Educated healthcare professionals on the role of genetics in healthcare.",
        "Participated in quality improvement initiatives related to genetic services."
    ],

    "Gerontology": [
        "Provided comprehensive care for older adults, focusing on age-related health conditions.",
        "Managed medications for chronic conditions such as hypertension and diabetes.",
        "Conducted cognitive and functional assessments.",
        "Developed individualized care plans to promote healthy aging.",
        "Monitored for geriatric syndromes such as falls, delirium, and frailty.",
        "Provided end-of-life care and palliative interventions.",
        "Educated families on how to care for aging relatives.",
        "Coordinated with interdisciplinary teams for holistic geriatric care.",
        "Engaged in preventative care through screenings and immunizations.",
        "Managed wound care, especially for pressure ulcers.",
        "Assisted with mobility and physical therapy.",
        "Provided psychosocial support for issues like isolation and depression.",
        "Handled advanced directives and ethical considerations in geriatric care.",
        "Participated in research pertaining to aging and geriatric nursing.",
        "Managed issues related to polypharmacy and medication reconciliation.",
        "Conducted in-home assessments for community-dwelling seniors.",
        "Assisted with activities of daily living (ADLs).",
        "Implemented fall prevention strategies and environmental safety checks.",
        "Coordinated transitional care from hospital to home or long-term facilities.",
        "Participated in continuing education in gerontology and geriatric syndromes."
    ],

    "Infectious Disease": [
        "Managed care for patients with bacterial, viral, fungal, or parasitic infections.",
        "Administered antibiotics and antivirals as part of infection management.",
        "Conducted screenings and diagnostic tests for infectious diseases.",
        "Educated patients on infection control and prevention measures.",
        "Implemented and maintained strict infection control protocols.",
        "Coordinated with public health agencies for disease tracking and management.",
        "Handled isolation procedures for highly infectious patients.",
        "Provided travel vaccinations and advice.",
        "Assisted in the management of emerging infectious diseases.",
        "Managed patient care during disease outbreaks.",
        "Conducted patient and community education on sexually transmitted infections (STIs).",
        "Monitored and managed complications and co-infections.",
        "Participated in research and clinical trials related to infectious diseases.",
        "Documented detailed patient histories, symptoms, and treatment outcomes.",
        "Managed long-term care for chronic infections like HIV/AIDS and hepatitis.",
        "Provided emotional and psychological support to patients.",
        "Participated in interdisciplinary care planning.",
        "Managed biohazard waste and sterilization protocols.",
        "Kept updated on epidemiological trends and emerging pathogens.",
        "Engaged in continuous professional development in infectious disease care."
    ],

    "Infusion Therapy": [
        "Administered intravenous (IV) medications, fluids, and blood products.",
        "Monitored patients for reactions or complications during infusion.",
        "Educated patients and families on infusion procedures and home care.",
        "Managed venous access devices like PICCs, central lines, and ports.",
        "Collaborated with pharmacists for medication preparation and dosage.",
        "Conducted pre-infusion assessments, including medication history and vital signs.",
        "Coordinated with care teams for timely and safe infusions.",
        "Managed documentation for treatment protocols and patient responses.",
        "Maintained strict aseptic technique during infusion procedures.",
        "Conducted post-infusion assessments and follow-ups.",
        "Handled emergency interventions for infusion-related complications.",
        "Engaged in quality improvement for infusion services.",
        "Assisted with outpatient infusion therapies.",
        "Provided psychosocial support to patients undergoing long-term infusions.",
        "Managed the preparation and labeling of infusion solutions.",
        "Maintained infusion pumps and other related equipment.",
        "Participated in continuing education and certifications in infusion therapy.",
        "Conducted patient scheduling and logistical coordination.",
        "Monitored for medication interactions and contraindications.",
        "Assisted in research and data collection related to infusion therapy."
    ],

    "Plastic Surgery": [
        "Assisted in surgical procedures such as breast augmentations, facelifts, and liposuctions.",
        "Conducted preoperative assessments and education.",
        "Managed postoperative care, including wound management and pain control.",
        "Educated patients on post-surgical care and potential complications.",
        "Coordinated with surgical teams for procedure planning and execution.",
        "Managed documentation for surgical interventions and outcomes.",
        "Provided emotional support to patients undergoing cosmetic or reconstructive procedures.",
        "Administered medications for surgical preparation and postoperative care.",
        "Managed potential complications like infections or poor wound healing.",
        "Conducted patient scheduling and follow-up appointments.",
        "Monitored patient vital signs during surgical procedures.",
        "Maintained strict aseptic environments in operating rooms.",
        "Handled equipment preparation and sterilization.",
        "Engaged in patient consultations for surgical planning.",
        "Participated in professional development in the field of plastic surgery nursing.",
        "Coordinated referrals to other specialists for comprehensive care.",
        "Managed patient records for long-term outcomes and revisions.",
        "Participated in research and quality improvement in plastic surgery.",
        "Educated patients on non-surgical interventions like fillers or laser treatments.",
        "Ensured compliance with healthcare regulations and safety protocols in surgical settings."
    ],

    "Reproductive Health": [
        "Managed contraceptive care, including birth control counseling and administration.",
        "Conducted screenings for sexually transmitted infections (STIs).",
        "Provided preconception care and fertility counseling.",
        "Educated patients on reproductive anatomy and menstrual health.",
        "Administered hormonal treatments and assisted in fertility procedures.",
        "Coordinated with obstetricians and gynecologists for comprehensive care.",
        "Managed menopausal symptoms and hormonal imbalances.",
        "Conducted pregnancy tests and early pregnancy assessments.",
        "Provided abortion care, including pre- and post-procedure counseling.",
        "Handled sexual health concerns and dysfunctions.",
        "Documented detailed patient histories and reproductive health data.",
        "Managed pelvic exams and Pap smears.",
        "Educated patients on various family planning methods.",
        "Participated in community education on reproductive health issues.",
        "Handled psychosocial aspects of reproductive health, including emotional support.",
        "Managed LGBTQ+ reproductive health needs.",
        "Engaged in professional development in reproductive health nursing.",
        "Participated in reproductive health research and data collection.",
        "Coordinated with social services for at-risk populations.",
        "Managed prenatal care, including education and medication management."
    ],

    "Rheumatology": [
        "Managed care for patients with autoimmune and inflammatory diseases like rheumatoid arthritis and lupus.",
        "Administered medications such as immunosuppressants and biologics.",
        "Conducted joint exams and assessments for disease activity.",
        "Educated patients on disease management and lifestyle modifications.",
        "Managed symptom relief interventions like pain medications and hot/cold therapies.",
        "Coordinated diagnostic tests like X-rays, MRIs, and lab work.",
        "Collaborated with interdisciplinary teams for comprehensive rheumatologic care.",
        "Monitored patients for medication side effects and interactions.",
        "Conducted patient education on disease progression and treatment options.",
        "Handled documentation of disease activity, treatment plans, and outcomes.",
        "Managed flare-ups and disease exacerbations.",
        "Provided psychosocial support for chronic disease management.",
        "Participated in continuing education in rheumatology and autoimmune diseases.",
        "Engaged in patient advocacy for accessing medications and therapies.",
        "Assisted in therapeutic procedures like joint injections.",
        "Participated in research and clinical trials for rheumatologic conditions.",
        "Educated families on genetic and environmental risk factors.",
        "Handled assistive devices like braces or canes for mobility issues.",
        "Monitored and documented long-term treatment outcomes.",
        "Engaged in quality improvement for rheumatology services."
    ],

    "Substance Abuse": [
        "Managed care for patients undergoing detoxification from drugs or alcohol.",
        "Administered medications to manage withdrawal symptoms and cravings.",
        "Conducted comprehensive psychological and physical assessments.",
        "Educated patients on addiction recovery and relapse prevention.",
        "Coordinated with interdisciplinary teams for holistic addiction treatment.",
        "Managed potential complications like seizures or delirium tremens.",
        "Provided psychosocial support and crisis intervention.",
        "Developed individualized care plans focusing on long-term recovery.",
        "Conducted group therapy and educational sessions.",
        "Handled documentation for patient progress and treatment outcomes.",
        "Assisted in the transition to outpatient or long-term rehab facilities.",
        "Participated in community outreach and education on substance abuse.",
        "Managed medication-assisted treatment (MAT) protocols.",
        "Conducted urine drug tests and other screenings.",
        "Coordinated with law enforcement and social services as necessary.",
        "Managed family counseling and support services.",
        "Engaged in professional development in the field of addiction nursing.",
        "Participated in research and quality improvement projects.",
        "Maintained strict patient confidentiality and ethical considerations.",
        "Managed care for co-occurring mental health disorders."
    ],

    "Toxicology": [
        "Managed care for patients exposed to toxic substances, including overdoses.",
        "Administered antidotes and other treatments specific to the toxicant.",
        "Conducted comprehensive assessments to identify the toxic substance involved.",
        "Coordinated with poison control centers for treatment guidance.",
        "Managed supportive care measures like ventilation or fluid resuscitation.",
        "Handled documentation for patient exposures and treatment interventions.",
        "Educated patients and families on poison prevention.",
        "Participated in community outreach and education on toxicology issues.",
        "Engaged in research and data collection for toxic exposures.",
        "Managed decontamination procedures for chemical or hazardous material exposures.",
        "Provided psychosocial support for accidental or intentional poisonings.",
        "Monitored for long-term complications and systemic effects of toxic exposure.",
        "Collaborated with interdisciplinary teams for comprehensive toxicology care.",
        "Participated in continuing education in the field of toxicology nursing.",
        "Handled laboratory tests like blood levels and urine toxicology screens.",
        "Managed substance abuse cases with toxicological implications.",
        "Coordinated with public health agencies for outbreak investigations.",
        "Managed occupational exposures and workplace safety assessments.",
        "Handled reporting and documentation for legal and forensic considerations.",
        "Engaged in quality improvement projects for toxicology services."
    ],

    "Triage": [
        "Conducted initial assessments of patients presenting in the emergency room or urgent care.",
        "Prioritized patient care based on severity and urgency of conditions.",
        "Managed rapid interventions for life-threatening cases.",
        "Coordinated with emergency medical teams for incoming trauma or critical patients.",
        "Handled documentation for initial assessments and triage decisions.",
        "Educated patients on appropriate levels of care and service utilization.",
        "Managed phone triage for remote or telemedicine consultations.",
        "Handled resource allocation during high-volume or crisis situations.",
        "Coordinated with interdisciplinary teams for patient flow management.",
        "Participated in disaster preparedness and mass casualty triage protocols.",
        "Managed initial diagnostic tests like EKGs or rapid blood tests.",
        "Educated families on patient status and waiting times.",
        "Participated in quality improvement initiatives for triage services.",
        "Handled referrals to specialists or secondary care services.",
        "Managed patient records and documentation for legal compliance.",
        "Engaged in professional development in the field of triage nursing.",
        "Conducted patient follow-ups for discharged or transferred cases.",
        "Managed infection control measures in the triage area.",
        "Handled psychosocial support and crisis intervention as necessary.",
        "Participated in interdisciplinary case reviews and debriefings."
    ],

    "Addiction Nursing": [
        "Conducted assessments to determine the level of addiction and co-occurring mental health disorders.",
        "Administered medications to alleviate withdrawal symptoms and support recovery.",
        "Developed personalized treatment plans based on patient needs and recovery goals.",
        "Provided counseling and emotional support to patients and their families.",
        "Educated patients on strategies for managing cravings and avoiding relapse.",
        "Coordinated care with psychiatrists, psychologists, and social workers for comprehensive treatment.",
        "Participated in community outreach programs to raise awareness about addiction and treatment options.",
        "Facilitated support group meetings for patients and family members.",
        "Managed case documentation, including treatment plans, progress notes, and discharge summaries.",
        "Conducted ongoing training to stay updated on best practices in addiction treatment.",
        "Assisted in the management of detoxification processes for patients with substance use disorders.",
        "Monitored patients for signs of distress or complications during recovery.",
        "Collaborated with legal and social services to support patients' needs.",
        "Implemented harm reduction strategies to minimize the risks associated with substance use.",
        "Engaged in research projects to improve addiction treatment outcomes.",
        "Advocated for patient access to addiction treatment and support services.",
        "Utilized technology-based interventions, such as telehealth, for remote patient support.",
        "Participated in policy development to enhance addiction nursing practices.",
        "Provided training to nursing staff on addiction care and management.",
        "Worked closely with rehabilitation centers to facilitate smooth transitions for ongoing care."
    ],

    "Cardiovascular": [
        "Assessed patients for cardiovascular risk factors and symptoms of heart disease.",
        "Administered medications and treatments for heart conditions, including post-operative care.",
        "Monitored patients' vital signs and cardiac rhythms to detect complications early.",
        "Educated patients and families on heart-healthy lifestyles and disease management.",
        "Participated in cardiac rehabilitation programs to support patient recovery and health improvement.",
        "Coordinated with cardiologists and other healthcare professionals for comprehensive care.",
        "Performed stress tests and other diagnostic procedures to evaluate heart function.",
        "Managed care for patients with chronic conditions such as hypertension and heart failure.",
        "Assisted in surgical procedures, including pre-operative and post-operative care for cardiac surgeries.",
        "Developed and implemented care plans tailored to individual patient needs.",
        "Provided emotional support and counseling to patients dealing with heart disease.",
        "Conducted research to improve cardiovascular nursing practices and patient outcomes.",
        "Utilized advanced technologies for patient monitoring and treatment.",
        "Participated in community outreach programs to educate the public on cardiovascular health.",
        "Managed patient records, ensuring accuracy and confidentiality.",
        "Advocated for patient needs and access to quality cardiovascular care.",
        "Engaged in professional development opportunities to enhance expertise in cardiovascular nursing.",
        "Coordinated patient discharge planning to ensure continuity of care.",
        "Implemented infection control measures to prevent complications in cardiac patients.",
        "Supported families in understanding and managing genetic or lifestyle-related heart disease risks."
    ],

    "Case Management": [
        "Conducted comprehensive assessments to identify patient needs, preferences, and resources.",
        "Developed, implemented, and regularly updated individualized care plans in collaboration with healthcare teams.",
        "Coordinated healthcare services across multiple providers to ensure continuity of care.",
        "Advocated for patients and families to access necessary healthcare services and resources.",
        "Monitored patient progress and outcomes, adjusting care plans as necessary.",
        "Facilitated communication between patients, families, and healthcare professionals.",
        "Managed transitions of care, including discharges from hospitals to home or other care facilities.",
        "Identified and addressed potential barriers to optimal patient care and outcomes.",
        "Educated patients and families on health conditions, treatment options, and healthcare navigation.",
        "Utilized evidence-based practices to guide case management processes and decisions.",
        "Participated in multidisciplinary team meetings to discuss complex cases and coordinate care.",
        "Maintained comprehensive documentation of all case management activities and communications.",
        "Implemented strategies to reduce healthcare costs while maintaining or improving quality of care.",
        "Engaged in ethical decision-making and maintained patient confidentiality.",
        "Assessed patient satisfaction and quality of care to identify areas for improvement.",
        "Utilized health information technology to track patient data and outcomes.",
        "Provided crisis intervention and support during emergencies or significant health events.",
        "Collaborated with community resources to support patient health and wellness.",
        "Advocated for policy changes to improve healthcare access and quality for patients.",
        "Continued professional development to stay current with case management practices and healthcare trends."
    ],

    "Emergency Room": [
        "Provided immediate assessment and stabilization of patients presenting with acute conditions.",
        "Administered emergency medications and treatments under high-pressure situations.",
        "Performed advanced life support and resuscitation techniques as needed.",
        "Managed trauma care for patients with critical injuries, coordinating with trauma teams.",
        "Conducted rapid diagnostic tests to aid in the quick identification of conditions.",
        "Educated patients and families on emergency procedures and follow-up care.",
        "Prioritized care based on the severity of conditions in a fast-paced environment.",
        "Maintained proficiency in emergency medical equipment and technology.",
        "Collaborated closely with physicians, paramedics, and other healthcare professionals.",
        "Documented all patient care accurately and comprehensively in real-time.",
        "Provided emotional support and crisis intervention for patients and their families.",
        "Participated in emergency department quality improvement and safety initiatives.",
        "Managed patient flow to ensure timely treatment and effective use of resources.",
        "Engaged in continuous education and training in emergency care and procedures.",
        "Coordinated post-emergency care and referrals to appropriate healthcare services.",
        "Implemented infection control measures to prevent the spread of disease.",
        "Assisted in the development of emergency department policies and protocols.",
        "Advocated for patient rights and access to emergency healthcare services.",
        "Participated in disaster preparedness planning and response within the hospital and community.",
        "Maintained a high level of ethical standards and confidentiality in patient care."
    ],

    "Midwifery": [
        "Conducted prenatal visits and provided comprehensive antenatal care.",
        "Educated expecting parents on pregnancy, labor, delivery, and postpartum care.",
        "Monitored maternal and fetal well-being throughout pregnancy and labor.",
        "Facilitated natural childbirth and provided support during labor and delivery.",
        "Administered medications and performed medical interventions when necessary.",
        "Assisted with water births and other alternative birthing methods.",
        "Provided postpartum care to mothers and newborns, including breastfeeding support.",
        "Conducted newborn examinations and administered necessary vaccinations.",
        "Collaborated with obstetricians and pediatricians for high-risk pregnancies and complications.",
        "Managed emergency situations during labor and delivery, ensuring the safety of mother and baby.",
        "Advocated for the mother's birth plan and preferences throughout the birthing process.",
        "Led childbirth education classes for expecting families.",
        "Participated in home births and provided comprehensive home care services.",
        "Monitored and treated minor complications in both mothers and newborns.",
        "Coordinated follow-up care and referrals to pediatric care for newborns.",
        "Maintained detailed records of patient care from prenatal visits to postpartum follow-ups.",
        "Advocated for women's health and reproductive rights in the healthcare system.",
        "Engaged in continuous education to stay updated on best practices in midwifery.",
        "Provided family planning and contraceptive counseling.",
        "Supported emotional and psychological well-being of mothers throughout the pregnancy journey."
    ],

    "Occupational": [
        "Conducted evaluations to assess patient's functional abilities and limitations.",
        "Developed individualized treatment plans to improve daily living and work skills.",
        "Provided adaptive equipment recommendations and training for better independence.",
        "Educated patients and families on coping strategies for daily life challenges.",
        "Implemented therapeutic exercises to enhance motor skills and coordination.",
        "Facilitated sensory integration therapies to improve processing and perception.",
        "Coordinated with multidisciplinary teams to provide holistic care.",
        "Monitored patient progress and adjusted treatment plans as necessary.",
        "Advocated for workplace modifications and ergonomic assessments to prevent injuries.",
        "Conducted cognitive rehabilitation sessions to improve memory, attention, and problem-solving skills.",
        "Provided hand therapy to improve strength and dexterity.",
        "Assisted patients in developing social skills and community integration techniques.",
        "Offered guidance on lifestyle modifications to support health and wellness.",
        "Engaged in research to develop new therapeutic techniques and interventions.",
        "Educated on injury prevention and health promotion in various settings.",
        "Managed patient documentation and reported on treatment outcomes.",
        "Participated in professional development and continuing education courses.",
        "Implemented play therapy techniques for pediatric patients to improve development.",
        "Collaborated with schools and employers to support patient needs.",
        "Advocated for patient access to occupational therapy services and resources."
    ],
    "Ophthalmology": [
        "Assisted in the diagnosis and treatment of eye diseases and injuries.",
        "Administered eye medications and injections as prescribed by ophthalmologists.",
        "Conducted patient education on eye care, including post-operative instructions.",
        "Performed pre-operative and post-operative care for patients undergoing eye surgery.",
        "Monitored patients for complications arising from eye diseases or surgeries.",
        "Assisted in various ophthalmic procedures, including cataract surgery and laser treatments.",
        "Conducted visual acuity tests and other eye examinations.",
        "Managed patient records, including documenting symptoms, treatments, and progress.",
        "Coordinated care with other healthcare professionals for patients with systemic diseases affecting the eyes.",
        "Provided support and counseling to patients dealing with vision loss or impairment.",
        "Participated in community health initiatives to promote eye health and prevent blindness.",
        "Engaged in professional development to stay current with advances in ophthalmic nursing.",
        "Assisted in the fitting and care instructions for contact lenses and eyeglasses.",
        "Managed the sterilization and maintenance of ophthalmic instruments and equipment.",
        "Educated patients on the management of chronic eye conditions, such as glaucoma and diabetic retinopathy.",
        "Coordinated appointments, surgeries, and follow-up care for patients.",
        "Assisted in emergency eye care and treatments.",
        "Engaged in research activities to improve patient care and treatment outcomes in ophthalmology.",
        "Advocated for patient safety and quality of care in ophthalmic treatments.",
        "Provided instruction on the use of assistive devices for patients with low vision."
    ],
    "Orthopaedic": [
        "Provided care for patients undergoing orthopaedic surgeries, such as joint replacements and fracture repairs.",
        "Managed post-operative pain and implemented pain management strategies.",
        "Assisted patients with mobility and taught safe movement techniques post-surgery.",
        "Conducted patient and family education on post-operative care and rehabilitation.",
        "Monitored patients for signs of complications, such as infections or thrombosis.",
        "Coordinated care with physical therapists and other rehabilitation professionals.",
        "Administered medications, including intravenous infusions and injections.",
        "Assisted in casting, splinting, and wound care for orthopaedic patients.",
        "Managed chronic conditions such as osteoporosis and arthritis.",
        "Participated in multidisciplinary team meetings to plan patient care.",
        "Engaged in professional development to stay current with advances in orthopaedic nursing.",
        "Advocated for patient needs and facilitated access to supportive resources.",
        "Managed patient documentation, including treatment plans and progress notes.",
        "Assisted in orthopaedic clinics and outpatient care settings.",
        "Provided emotional support and counseling to patients and families.",
        "Coordinated discharge planning and home care instructions for patients.",
        "Participated in research projects aimed at improving orthopaedic care and outcomes.",
        "Implemented infection control measures in the care of surgical wounds.",
        "Educated patients on disease prevention and health maintenance strategies.",
        "Collaborated with orthopaedic surgeons to ensure optimal patient care and outcomes."
    ],
    "Otolaryngology": [
        "Assisted in the diagnosis and treatment of ear, nose, and throat (ENT) disorders.",
        "Administered medications and treatments for conditions such as sinusitis, allergies, and ear infections.",
        "Prepared patients for and provided care after ENT surgical procedures, including tonsillectomies and nasal surgeries.",
        "Educated patients on the management of chronic ENT conditions and post-operative care.",
        "Performed wound care and removal of sutures/staples for surgical patients.",
        "Managed patient assessments, including audiometric testing and throat swabs.",
        "Coordinated with audiologists for the care of patients with hearing impairments.",
        "Monitored patients for complications arising from ENT surgeries or conditions.",
        "Provided support and counseling to patients undergoing treatment for head and neck cancers.",
        "Assisted in the fitting and care of hearing aids and other assistive devices.",
        "Managed patient records, documenting treatments, progress, and outcomes.",
        "Advocated for patient needs and facilitated referrals to specialists as needed.",
        "Participated in community outreach programs to promote ear and hearing health.",
        "Engaged in professional development to stay current with advances in otolaryngology nursing.",
        "Implemented infection control measures specific to ENT procedures and care.",
        "Coordinated care with speech therapists for patients requiring rehabilitation.",
        "Assisted in endoscopic procedures for diagnostic and treatment purposes.",
        "Provided patient education on preventive measures for ENT health.",
        "Managed the care of patients with vertigo and balance disorders.",
        "Engaged in research and quality improvement initiatives related to otolaryngology care."
    ],
    "Pain Management": [
        "Assessed and monitored patients for acute and chronic pain conditions.",
        "Administered pain medications and adjusted dosages based on patient feedback and pain levels.",
        "Implemented non-pharmacological pain management techniques, such as relaxation techniques, heat/cold therapy, and TENS.",
        "Educated patients and families on pain management plans and strategies.",
        "Collaborated with healthcare professionals to develop multidisciplinary pain management plans.",
        "Monitored patients for side effects and complications of pain medications.",
        "Advocated for patients to ensure effective pain relief and quality of life.",
        "Participated in research to improve pain management practices and patient outcomes.",
        "Provided emotional support to patients struggling with chronic pain.",
        "Coordinated care with physical therapists, psychologists, and other specialists.",
        "Managed documentation of pain assessments, interventions, and patient responses.",
        "Conducted patient and community education sessions on pain management and prevention.",
        "Implemented guidelines and protocols for safe and effective pain management.",
        "Participated in professional development to stay current with pain management techniques.",
        "Assisted in the evaluation of new pain management technologies and treatments.",
        "Advocated for alternative and complementary therapies in pain management.",
        "Coordinated with palliative care teams for patients with life-limiting conditions.",
        "Managed the care of patients undergoing procedures for pain relief, such as nerve blocks.",
        "Engaged in ethical decision-making regarding pain management and patient care.",
        "Provided guidance on lifestyle modifications to assist in pain management and prevention."
    ],
    "Pediatric ICU": [
        "Provided critical care to infants, children, and adolescents with life-threatening conditions.",
        "Monitored vital signs and responded to signs of distress or deterioration in patient conditions.",
        "Administered medications, fluids, and nutritional support as prescribed.",
        "Assisted in advanced procedures, such as intubation, central line insertion, and lumbar punctures.",
        "Collaborated with multidisciplinary teams to develop and implement comprehensive care plans.",
        "Educated families on the care and management of critically ill children.",
        "Utilized specialized pediatric equipment and monitored for proper functioning.",
        "Participated in life-saving resuscitative efforts and emergency care.",
        "Supported children and families through end-of-life care decisions and palliative care services.",
        "Conducted thorough patient assessments and documented all care activities and patient responses.",
        "Advocated for the needs and rights of pediatric patients and their families.",
        "Engaged in professional development to maintain expertise in pediatric critical care.",
        "Managed pain and provided comfort measures for critically ill or injured children.",
        "Coordinated with rehabilitation services to support recovery and development.",
        "Implemented infection control measures specific to the pediatric population.",
        "Participated in research and quality improvement initiatives to enhance pediatric ICU care.",
        "Assisted in the transition of care from ICU to step-down units or home care.",
        "Provided emotional support and crisis intervention for families during critical events.",
        "Utilized child life specialists to support the emotional and developmental needs of pediatric patients.",
        "Advocated for the involvement of families in the care and decision-making process."
    ],
    "Pre-Operative": [
        "Conducted pre-operative assessments, including medical history and physical examination.",
        "Educated patients and families about the surgical procedure, potential risks, and post-operative care.",
        "Ensured all necessary pre-operative tests and evaluations were completed.",
        "Coordinated with surgeons and anesthesiologists to review patient care plans.",
        "Managed patient anxiety through education and emotional support.",
        "Prepared patients for surgery, including skin preparation and IV insertion.",
        "Verified all surgical consents and documentation were in order.",
        "Administered pre-operative medications as prescribed.",
        "Monitored patients for any adverse reactions or changes in condition prior to surgery.",
        "Facilitated communication between the patient, family, and surgical team.",
        "Managed the logistics of surgery scheduling and operating room coordination.",
        "Participated in quality improvement initiatives to enhance pre-operative care and patient outcomes.",
        "Maintained strict adherence to infection control protocols.",
        "Provided instructions on post-operative care and recovery expectations.",
        "Ensured all patient questions and concerns were addressed prior to surgery.",
        "Collaborated with post-operative and recovery room nurses to ensure continuity of care.",
        "Engaged in professional development to stay current with advances in surgical nursing.",
        "Participated in the development and review of pre-operative protocols and procedures.",
        "Managed patient records, ensuring accurate and comprehensive documentation.",
        "Advocated for patient safety and well-being throughout the pre-operative process."
    ],
    "Pulmonary": [
        "Provided care for patients with acute and chronic respiratory conditions, including COPD, asthma, and pneumonia.",
        "Administered respiratory medications and treatments, such as inhalers, nebulizers, and oxygen therapy.",
        "Monitored patients' respiratory status, including oxygen saturation and lung function tests.",
        "Educated patients and families on respiratory care and management, including smoking cessation.",
        "Assisted with pulmonary rehabilitation exercises and techniques to improve lung function.",
        "Managed care for patients requiring mechanical ventilation or non-invasive ventilation support.",
        "Collaborated with interdisciplinary teams to develop comprehensive care plans for respiratory patients.",
        "Participated in the management of patients with sleep apnea, including CPAP and BiPAP therapy.",
        "Conducted patient assessments and documented progress and response to treatments.",
        "Provided support and care for patients undergoing diagnostic procedures, such as bronchoscopy.",
        "Engaged in professional development to stay current with advances in pulmonary medicine and nursing.",
        "Coordinated discharge planning and home care instructions for patients with respiratory conditions.",
        "Implemented infection control measures to prevent the spread of respiratory infections.",
        "Managed emergency situations, including respiratory distress and failure.",
        "Participated in research and quality improvement initiatives to enhance respiratory care.",
        "Advocated for patient access to pulmonary rehabilitation and support services.",
        "Assisted in the management of pulmonary hypertension and related conditions.",
        "Provided end-of-life care and support for patients with terminal respiratory illnesses.",
        "Educated the community on respiratory health, disease prevention, and early detection.",
        "Collaborated with public health initiatives to manage and prevent respiratory disease outbreaks."
    ],
    "Radiology": [
        "Provided care for patients undergoing diagnostic imaging procedures, such as MRI, CT scans, and X-rays.",
        "Administered contrast media and monitored patients for adverse reactions.",
        "Educated patients on the procedure process and post-procedure care.",
        "Assisted radiologists during invasive procedures, such as biopsies and catheter placements.",
        "Managed patient positioning to ensure optimal imaging results while ensuring patient comfort.",
        "Monitored vital signs and patient status throughout radiological procedures.",
        "Collaborated with the radiology team to ensure patient safety and radiation protection measures.",
        "Provided post-procedure care, including wound care for invasive procedures.",
        "Coordinated scheduling and logistics for imaging procedures.",
        "Documented all care and procedure details in the patient's medical record.",
        "Participated in emergency radiology procedures, providing rapid patient assessment and care.",
        "Engaged in professional development to stay informed about advancements in radiology and imaging techniques.",
        "Advocated for patient needs and comfort during radiological examinations.",
        "Managed care for patients with implanted devices or contraindications for certain imaging modalities.",
        "Assisted in the management of sedation and anesthesia for procedures requiring patient immobility.",
        "Coordinated with other healthcare teams to integrate imaging results into overall patient care plans.",
        "Implemented infection control practices specific to radiology departments.",
        "Participated in quality improvement projects to enhance radiology nursing practices and patient outcomes.",
        "Provided education and support for patients receiving radiation therapy.",
        "Advocated for the use of the latest imaging technology to improve diagnostic accuracy and patient care."
    ],
    "Rehabilitation": [
        "Assessed patients' rehabilitation needs and developed individualized care plans.",
        "Provided care and support for patients recovering from surgery, injury, or illness.",
        "Administered medications and treatments as part of the rehabilitation process.",
        "Educated patients and families on recovery processes and self-care techniques.",
        "Coordinated with physical, occupational, and speech therapists to provide comprehensive rehabilitation services.",
        "Monitored patients' progress and adjusted care plans as necessary.",
        "Assisted patients with mobility exercises and the use of assistive devices.",
        "Managed wound care and other post-operative care needs.",
        "Supported patients with neurological conditions, including stroke and traumatic brain injury.",
        "Advocated for patient needs and resources within the healthcare system.",
        "Facilitated group therapy sessions and support groups as part of the rehabilitation program.",
        "Participated in discharge planning to ensure a smooth transition to home or long-term care.",
        "Implemented strategies to manage pain and improve function.",
        "Monitored for complications or setbacks in the rehabilitation process.",
        "Engaged in research and quality improvement projects to enhance rehabilitation outcomes.",
        "Educated the community about injury prevention and the benefits of rehabilitation.",
        "Coordinated with insurance providers and social services to secure resources for patients.",
        "Provided emotional support and counseling to patients and families during the rehabilitation journey.",
        "Maintained comprehensive documentation of patient progress and interventions.",
        "Engaged in continuous education to stay abreast of advancements in rehabilitation nursing."
    ],
    "Surgical": [
        "Prepared patients for surgery, including pre-operative assessments and education.",
        "Assisted surgeons and surgical teams during operations, ensuring sterile techniques and patient safety.",
        "Managed post-operative care, including wound care, pain management, and monitoring for complications.",
        "Educated patients and families on post-surgical care and recovery.",
        "Coordinated with multidisciplinary teams for comprehensive post-operative rehabilitation.",
        "Monitored patients for signs of infection or other post-surgical complications.",
        "Administered prescribed medications and treatments post-surgery.",
        "Assisted in the recovery room, monitoring patients as they regain consciousness.",
        "Facilitated discharge planning, including follow-up appointments and home care instructions.",
        "Participated in emergency surgeries, providing rapid patient assessment and preparation.",
        "Implemented evidence-based practices to improve surgical outcomes and patient safety.",
        "Engaged in professional development to maintain competency in surgical nursing.",
        "Advocated for patient needs and comfort during the surgical process.",
        "Managed documentation and patient records related to surgical procedures.",
        "Participated in quality improvement initiatives to enhance surgical care.",
        "Coordinated care for patients undergoing outpatient or day surgeries.",
        "Assisted with the management of surgical equipment and supplies.",
        "Provided support for patients undergoing minimally invasive and robotic surgeries.",
        "Engaged in research projects to advance surgical nursing practices.",
        "Supported the emotional and psychological needs of patients undergoing surgery."
    ],
    "Women's Health": [
        "Provided care and support for women across the lifespan, including gynecological, prenatal, and menopausal health.",
        "Conducted screenings and assessments, such as Pap smears and breast exams.",
        "Educated women on reproductive health, family planning, and contraception options.",
        "Supported women during pregnancy, childbirth, and the postpartum period.",
        "Coordinated care for women with gynecological conditions, such as endometriosis and PCOS.",
        "Managed hormone replacement therapy and other treatments for menopausal symptoms.",
        "Advocated for women's health rights and access to care.",
        "Participated in community outreach programs focused on women's health education.",
        "Provided care and counseling for women experiencing domestic violence or sexual assault.",
        "Collaborated with multidisciplinary teams to address the comprehensive health needs of women.",
        "Conducted research to improve women's health outcomes and care practices.",
        "Engaged in professional development specific to women's health nursing.",
        "Assisted with fertility treatments and counseling for women and couples.",
        "Implemented strategies to prevent and manage sexually transmitted infections.",
        "Educated women on preventive health measures and lifestyle modifications.",
        "Coordinated with oncology teams for women undergoing treatment for breast or gynecological cancers.",
        "Managed patient documentation and records related to women's health care.",
        "Participated in policy development to enhance women's health services.",
        "Provided emotional support and counseling for women facing health challenges.",
        "Engaged in quality improvement initiatives to enhance the care and services provided to women."
    ],
    "LTC": [
        "Provided comprehensive care for elderly and chronically ill patients requiring long-term assistance.",
        "Administered medications and monitored for side effects and interactions.",
        "Conducted regular assessments to monitor health status and identify changes in condition.",
        "Developed and implemented individualized care plans in collaboration with healthcare teams.",
        "Assisted with activities of daily living, ensuring dignity and promoting independence.",
        "Managed wound care and provided instructions for pressure ulcer prevention.",
        "Coordinated with physical and occupational therapists to support rehabilitation and mobility.",
        "Educated patients and families on disease management and health maintenance.",
        "Advocated for patient needs and preferences within the long-term care facility.",
        "Monitored nutritional status and worked with dietitians to ensure appropriate dietary interventions.",
        "Implemented infection control measures to prevent the spread of illness.",
        "Provided palliative care and supported patients and families through end-of-life decisions.",
        "Managed documentation and patient records, ensuring accuracy and compliance with regulations.",
        "Participated in care planning meetings to review patient progress and adjust care plans.",
        "Facilitated communication between patients, families, and the healthcare team.",
        "Engaged in quality improvement initiatives to enhance care delivery and patient satisfaction.",
        "Supported mental and emotional well-being through social activities and therapeutic interventions.",
        "Coordinated discharge planning and transition to other care settings as needed.",
        "Provided training and supervision to nursing assistants and junior staff.",
        "Engaged in professional development to stay current with best practices in long-term care nursing."
    ],
    "Long Term Ccare": [
        "Provided comprehensive care for elderly and chronically ill patients requiring long-term assistance.",
        "Administered medications and monitored for side effects and interactions.",
        "Conducted regular assessments to monitor health status and identify changes in condition.",
        "Developed and implemented individualized care plans in collaboration with healthcare teams.",
        "Assisted with activities of daily living, ensuring dignity and promoting independence.",
        "Managed wound care and provided instructions for pressure ulcer prevention.",
        "Coordinated with physical and occupational therapists to support rehabilitation and mobility.",
        "Educated patients and families on disease management and health maintenance.",
        "Advocated for patient needs and preferences within the long-term care facility.",
        "Monitored nutritional status and worked with dietitians to ensure appropriate dietary interventions.",
        "Implemented infection control measures to prevent the spread of illness.",
        "Provided palliative care and supported patients and families through end-of-life decisions.",
        "Managed documentation and patient records, ensuring accuracy and compliance with regulations.",
        "Participated in care planning meetings to review patient progress and adjust care plans.",
        "Facilitated communication between patients, families, and the healthcare team.",
        "Engaged in quality improvement initiatives to enhance care delivery and patient satisfaction.",
        "Supported mental and emotional well-being through social activities and therapeutic interventions.",
        "Coordinated discharge planning and transition to other care settings as needed.",
        "Provided training and supervision to nursing assistants and junior staff.",
        "Engaged in professional development to stay current with best practices in long-term care nursing."
    ]


};
export const getAllBulletPoints = (unit) => {
    return specialtiesBulletPoints[unit] || [];
};

