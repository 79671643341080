import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    entryContainer: {
        // Adjust the bottom padding to create space between entries
        paddingBottom: theme.spacing(2),
    },
    deleteButton: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        padding: theme.spacing(1), // Adjust to match the size of the TextField
    },

}));

function WorkHistoryEntry(props) {
    const classes = useStyles();
    return (
        <div className={classes.scrollableContainer}>
            <Grid container spacing={2} sx={{ mb: 2 }} className="work-history-entry">
                <Grid item xs={6}>
                    <TextField
                        className='work-history-input'
                        variant="outlined"
                        fullWidth
                        placeholder="Facility Name"
                        value={props.entry.facilityName}
                        onChange={e => props.setEntryData('facilityName', e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        className='work-history-input'
                        variant="outlined"
                        fullWidth
                        placeholder="Location"
                        value={props.entry.location}
                        onChange={e => props.setEntryData('location', e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        className='work-history-input'
                        variant="outlined"
                        fullWidth
                        placeholder="Bed Count"
                        type="number"
                        value={props.entry.bedCount}
                        onChange={e => props.setEntryData('bedCount', e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        className='work-history-input'
                        variant="outlined"
                        fullWidth
                        placeholder="Trauma Level"
                        type="number"
                        value={props.entry.traumaLevel}
                        onChange={e => props.setEntryData('traumaLevel', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        className='work-history-input'
                        variant="outlined"
                        fullWidth
                        placeholder="EMR System Used"
                        value={props.entry.emrSystem}
                        onChange={e => props.setEntryData('emrSystem', e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        className='work-history-input'
                        variant="outlined"
                        fullWidth
                        placeholder="Start Date"
                        value={props.entry.startDate}
                        onChange={e => props.setEntryData('startDate', e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        className='work-history-input'
                        variant="outlined"
                        fullWidth
                        placeholder="End Date"
                        value={props.entry.endDate}
                        onChange={e => props.setEntryData('endDate', e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Unit</InputLabel>
                        <Select
                            label="Unit"
                            value={props.entry.unit}
                            onChange={e => props.setEntryData('unit', e.target.value)}
                        >
                            <MenuItem value="">Select Unit</MenuItem>
                            <MenuItem value="Acute Care">Acute Care</MenuItem>
                            <MenuItem value="Addiction Nursing">Addiction Nursing</MenuItem>
                            <MenuItem value="Allergy/Immunology">Allergy/Immunology</MenuItem>
                            <MenuItem value="Ambulatory Care">Ambulatory Care</MenuItem>
                            <MenuItem value="Anesthesia">Anesthesia</MenuItem>
                            <MenuItem value="Bariatric Nursing">Bariatric Nursing</MenuItem>
                            <MenuItem value="Burn Unit">Burn Unit</MenuItem>
                            <MenuItem value="Cardiac ICU">Cardiac ICU</MenuItem>
                            <MenuItem value="Cardiovascular">Cardiovascular</MenuItem>
                            <MenuItem value="Case Management">Case Management</MenuItem>
                            <MenuItem value="Cath Lab">Cath Lab</MenuItem>
                            <MenuItem value="Certified Surgical Tech (CST)">Certified Surgical Tech (CST)</MenuItem>
                            <MenuItem value="Community Health">Community Health</MenuItem>
                            <MenuItem value="Critical Care">Critical Care</MenuItem>
                            <MenuItem value="CT Tech">CT Tech</MenuItem>
                            <MenuItem value="CVICU">CVICU</MenuItem>
                            <MenuItem value="Dermatology">Dermatology</MenuItem>
                            <MenuItem value="Diabetes Management">Diabetes Management</MenuItem>
                            <MenuItem value="Dialysis">Dialysis</MenuItem>
                            <MenuItem value="ED">ED</MenuItem>
                            <MenuItem value="Emergency Room">Emergency Room</MenuItem>
                            <MenuItem value="Endoscopy">Endoscopy</MenuItem>
                            <MenuItem value="ER Trauma">ER Trauma</MenuItem>
                            <MenuItem value="Family Nurse Practitioner">Family Nurse Practitioner</MenuItem>
                            <MenuItem value="Gastroenterology">Gastroenterology</MenuItem>
                            <MenuItem value="Genetics">Genetics</MenuItem>
                            <MenuItem value="Geriatrics">Geriatrics</MenuItem>
                            <MenuItem value="Gerontology">Gerontology</MenuItem>
                            <MenuItem value="Home Health">Home Health</MenuItem>
                            <MenuItem value="Hospice">Hospice</MenuItem>
                            <MenuItem value="ICU">ICU</MenuItem>
                            <MenuItem value="Infectious Disease">Infectious Disease</MenuItem>
                            <MenuItem value="Infusion Therapy">Infusion Therapy</MenuItem>
                            <MenuItem value="Interventional Radiology">Interventional Radiology</MenuItem>
                            <MenuItem value="Labor and Delivery">Labor and Delivery</MenuItem>
                            <MenuItem value="Med/Surg">Med/Surg</MenuItem>
                            <MenuItem value="Med/Surg Tele">Med/Surg Tele</MenuItem>
                            <MenuItem value="Medical Oncology">Medical Oncology</MenuItem>
                            <MenuItem value="Midwifery">Midwifery</MenuItem>
                            <MenuItem value="Neonatal">Neonatal</MenuItem>
                            <MenuItem value="Nephrology">Nephrology</MenuItem>
                            <MenuItem value="Neuro ICU">Neuro ICU</MenuItem>
                            <MenuItem value="NICU">NICU</MenuItem>
                            <MenuItem value="OB/GYN">OB/GYN</MenuItem>
                            <MenuItem value="Occupational Health">Occupational Health</MenuItem>
                            <MenuItem value="Occupational Therapy">Occupational Therapy</MenuItem>
                            <MenuItem value="Oncology">Oncology</MenuItem>
                            <MenuItem value="Ophthalmology">Ophthalmology</MenuItem>
                            <MenuItem value="Orthopaedic">Orthopaedic</MenuItem>
                            <MenuItem value="Orthopedics">Orthopedics</MenuItem>
                            <MenuItem value="OR">OR</MenuItem>
                            <MenuItem value="Otolaryngology">Otolaryngology</MenuItem>
                            <MenuItem value="PACU">PACU</MenuItem>
                            <MenuItem value="Pain Management">Pain Management</MenuItem>
                            <MenuItem value="Pediatric ICU">Pediatric ICU</MenuItem>
                            <MenuItem value="Pediatrics">Pediatrics</MenuItem>
                            <MenuItem value="PICU">PICU</MenuItem>
                            <MenuItem value="Plastic Surgery">Plastic Surgery</MenuItem>
                            <MenuItem value="Postpartum">Postpartum</MenuItem>
                            <MenuItem value="Pre-Operative">Pre-Operative</MenuItem>
                            <MenuItem value="Pulmonary">Pulmonary</MenuItem>
                            <MenuItem value="Psych">Psych</MenuItem>
                            <MenuItem value="Rad Tech">Rad Tech</MenuItem>
                            <MenuItem value="Radiology">Radiology</MenuItem>
                            <MenuItem value="Rehabilitation">Rehabilitation</MenuItem>
                            <MenuItem value="Reproductive Health">Reproductive Health</MenuItem>
                            <MenuItem value="Respiratory Therapist (rrt)">Respiratory Therapist (rrt)</MenuItem>
                            <MenuItem value="Rheumatology">Rheumatology</MenuItem>
                            <MenuItem value="School Nurse">School Nurse</MenuItem>
                            <MenuItem value="Stepdown">Stepdown</MenuItem>
                            <MenuItem value="Substance Abuse">Substance Abuse</MenuItem>
                            <MenuItem value="Surgical">Surgical</MenuItem>
                            <MenuItem value="Surgical Oncology">Surgical Oncology</MenuItem>
                            <MenuItem value="Telemetry">Telemetry</MenuItem>
                            <MenuItem value="Toxicology">Toxicology</MenuItem>
                            <MenuItem value="Transplant">Transplant</MenuItem>
                            <MenuItem value="Triage">Triage</MenuItem>
                            <MenuItem value="Urology">Urology</MenuItem>
                            <MenuItem value="Women's Health">Women's Health</MenuItem>
                            <MenuItem value="Wound Care">Wound Care</MenuItem>

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        className='work-history-input'
                        variant="outlined"
                        fullWidth
                        placeholder="Employment Type"
                        value={props.entry.employmentType}
                        onChange={e => props.setEntryData('employmentType', e.target.value)}
                    />
                </Grid>
                <Grid item xs={6} container justifyContent="center" alignItems="center">
                    <IconButton onClick={() => props.onDelete(props.entry.id)} color="error">
                        <DeleteIcon />
                    </IconButton>
                </Grid>


            </Grid>
        </div>
    );
}

export default WorkHistoryEntry;