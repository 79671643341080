import { Alert, TextField, Button } from '@mui/material';
import React, { useState } from 'react';
import { updatePassword } from '../../util/auth';
import { Link, redirect, useParams } from 'react-router-dom';

const ResetPassword = () => {
    const [messages, setMessages] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [disabled, setDisabled] = useState(false);
    const {uidb64, token} = useParams();

    const handlePasswordUpdate = () => {
        setMessages({})
        setSuccessMessage('')
        setDisabled(true)
        const formData = new FormData();
        formData.append('password', password)
        formData.append('password2', confirmPassword)
        updatePassword(formData, uidb64, token).then(({data, status}) => {
            setDisabled(false)
            if (status === 200) {
                setSuccessMessage(data.message)
                setTimeout(() => {
                    redirect('/home');
                }, 3000)
            }
            if (status === 400) {
                setMessages(data)
                if (data.hasOwnProperty('non_field_errors')) {
                  setMessages({'detail': data.non_field_errors})
                }
            }
        })
    }

    return (
        <div>
            {messages.detail && <p><Alert severity="error">{messages.detail}</Alert></p>}
            {successMessage && <p><Alert severity='success'>{successMessage}</Alert></p>}
            <form>
                <TextField label="Password" variant="outlined" type="password" fullWidth style={{ marginBottom: "1rem" }} onChange={(e) => setPassword(e.target.value)} error={messages.password ? true : false} helperText={messages.password && messages.password} />
                <TextField label="Confirm Password" variant="outlined" type="password" fullWidth style={{ marginBottom: "1rem" }} onChange={(e) => setConfirmPassword(e.target.value)} error={messages.password2 ? true : false} helperText={messages.password2 && messages.password2} />
                <Button disabled={disabled} variant="contained" color="primary" onClick={handlePasswordUpdate}>Reset Password</Button>
                <p><Link to="/home">Go back</Link></p>
            </form>
        </div>
    );
};

export default ResetPassword;
