import axios from 'axios';


export function getAccessToken() {
  return localStorage.getItem('accessToken');
}

export function setAccessToken(token) {
  if (getAccessToken()) {
    localStorage.removeItem('accessToken')
  }
  localStorage.setItem('accessToken', token)
}

export function removeAccessToken() {
  if (getAccessToken()) {
    localStorage.removeItem('accessToken')
  }
}

export async function login(formData) {
  const login = await axios.post('https://instaresumebackend.azurewebsites.net/api/login/', formData, {
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  })
    .then((response) => {
      setAccessToken(response.data.access);
      return response;
    })
    .catch(({ response }) => {
      console.log(response);
      return response;
    });
  return await login;
}

export async function register(formData) {
  formData.password2 = formData.password;
  const login = await axios.post('https://instaresumebackend.azurewebsites.net/api/register/', formData, {
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  })
    .then((response) => {
      setAccessToken(response.data.access);
      return response;
    })
    .catch(({ response }) => {
      console.log(response);
      return response;
    });
  return await login;
}

export async function getAuthUser() {
  const token = getAccessToken()
  if (token === null) {
    return;
  }
  const user = await axios.get('/api/user', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }).then((response) => {
    return response.data
  }).catch(({ response }) => {
    if (response.status === 401 && response.data.code === 'user_not_found') {
      removeAccessToken()
      window.location.reload()
    }
    console.log(response)
  })
  return await user;
}

export async function getResumes() {
  const token = getAccessToken();
  if (token === null) {
    return;
  }
  const resumes = await axios.get('/api/user/resume', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }).then((response) => {
    return response.data
  }).catch(({ response }) => {
    console.log(response)
  })
  return await resumes
}

export async function deleteResume(id) {
  const token = getAccessToken();
  if (token === null) {
    return;
  }
  const deleteResume = await axios.delete('/api/user/resume/' + id + '/delete', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  }).then((response) => {
    return response.data
  }).catch(({ response }) => {
    console.log(response)
  })
  return await deleteResume
}

export async function resetPassword(formData) {
  const resetPassword = await axios.post('/api/send-reset-link', formData, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => {
    return response
  }).catch(({ response }) => {
    return response
  })
  return await resetPassword
}

export async function updatePassword(formData, uidb64, token) {
  const updatePassword = await axios.patch('/api/reset-password/' + uidb64 + '/' + token + '/', formData).then(response => {
    console.log(response)
    return response
  }).catch(({ response }) => {
    console.log(response)
    return response
  })
  return updatePassword
}
