import React from 'react';
import { Box, Container } from '@mui/material';
import instructionsImage from '../../assets/Images/Add a heading (2).png';

const Instructions = () => {


    return (
        <Container >
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 4,
                marginBottom: 4,
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}>
                <img src={instructionsImage} alt="Instructions" style={{ maxWidth: '100%', height: 'auto', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }} />
            </Box>
        </Container>
    ); 
}

export default Instructions; 