import React from 'react';
import { jsPDF } from 'jspdf';
import Chip from '@mui/material/Chip';
import GetAppIcon from '@mui/icons-material/GetApp';



function DownloadButton({ name, phone, email, educationArray, workHistory, isEditing, resumeId, setSuccessMessage, setErrorMessage }) {



    const handleDownload = async (name, phone, email, educationArray, workHistory) => {
        try {
            // Initialize a new jsPDF document
            const doc = new jsPDF();
            doc.setFont("Times", "normal");
            let yPos = 30;
            const marginLeft = 20.4;
            const marginRight = 20.4;
            const lineHeight = 7;
            const usableWidth = doc.internal.pageSize.getWidth() - marginLeft - marginRight;
            const pageHeightMinusMargins = doc.internal.pageSize.getHeight() - (2 * marginLeft);

            const centerText = text => {
                const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                return (doc.internal.pageSize.getWidth() - textWidth) / 2;
            };

            const writeWrappedText = (text, x, y) => {
                text = String(text);
                const lines = doc.splitTextToSize(text, usableWidth);
                for (let line of lines) {
                    doc.text(line, x, y);
                    y += lineHeight;
                }
                return y;
            };

            // Add name, phone, and email to the PDF
            doc.setFont("Times", "bold");
            doc.setFontSize(22);
            doc.text(name, centerText(name), yPos);
            yPos += 7;
            if (yPos > pageHeightMinusMargins) {
                doc.addPage();
                yPos = marginLeft;
            }

            doc.setFontSize(14);
            doc.setFont("Times", "normal");
            doc.text(phone, centerText(phone), yPos);
            yPos += 7;
            if (yPos > pageHeightMinusMargins) {
                doc.addPage();
                yPos = marginLeft;
            }

            doc.text(email, centerText(email), yPos);
            yPos += 10;
            if (yPos > pageHeightMinusMargins) {
                doc.addPage();
                yPos = marginLeft;
            }

            if (educationArray && Array.isArray(educationArray)) {
                doc.setFont("Times", "bold");
                doc.setFontSize(18);
                yPos += 5;
                doc.text("Education", marginLeft, yPos);
                yPos += 7;

                doc.setFontSize(12);
                doc.setFont("Times", "normal");

                for (const eduEntry of educationArray) {
                    // Write the school name
                    doc.setFont("Times", "bold");
                    doc.text(eduEntry.schoolName, marginLeft, yPos);
                    yPos += 7;

                    // Right align the dates on the same line as the degree
                    const dateWidth = doc.getStringUnitWidth(eduEntry.dates) * doc.getFontSize() / doc.internal.scaleFactor;
                    const dateX = doc.internal.pageSize.getWidth() - marginRight - dateWidth;

                    // Write the degree, followed by the dates on the same line
                    doc.setFont("Times", "normal");
                    doc.text(eduEntry.degree, marginLeft, yPos);
                    doc.text(eduEntry.dates, dateX, yPos);
                    yPos += 7; // Add spacing between education entries
                }

                yPos += 2;
                doc.line(marginLeft, yPos, doc.internal.pageSize.getWidth() - marginRight, yPos);
                yPos += 10;
            }

            // Add work history information to the PDF if available
            if (workHistory && Array.isArray(workHistory)) {
                doc.setFont("Times", "bold");
                doc.setFontSize(18);
                doc.text("Work History", marginLeft, yPos);
                yPos += 10;
                if (yPos > pageHeightMinusMargins) {
                    doc.addPage();
                    yPos = marginLeft;
                }

                doc.setFontSize(12);
                doc.setFont("Times", "normal");

                for (const entry of workHistory) {
                    doc.setFont("Times", "bold");
                    if (yPos > pageHeightMinusMargins) {
                        doc.addPage();
                        yPos = marginLeft;
                    }
                    let facilityDetails = '';
                    if (entry.bedCount) facilityDetails += `${entry.bedCount} Beds`;
                    if (entry.traumaLevel) {
                        facilityDetails += facilityDetails ? `, Trauma Level ${entry.traumaLevel}` : `Trauma Level ${entry.traumaLevel}`;
                    }
                    if (entry.emrSystem) {
                        facilityDetails += facilityDetails ? `, Charting: ${entry.emrSystem}` : `Charting: ${entry.emrSystem}`;
                    }

                    const facilityNameWithDetails = facilityDetails ? `${entry.facilityName} (${facilityDetails})` : entry.facilityName;

                    yPos = writeWrappedText(facilityNameWithDetails, marginLeft, yPos);

                    doc.setFont("Times", "normal");
                    if (yPos > pageHeightMinusMargins) {
                        doc.addPage();
                        yPos = marginLeft;
                    }
                    yPos = writeWrappedText(entry.location, marginLeft, yPos);
                    if (yPos > pageHeightMinusMargins) {
                        doc.addPage();
                        yPos = marginLeft;
                    }
                    yPos = writeWrappedText(`Unit: ${entry.unit}`, marginLeft, yPos);
                    if (yPos > pageHeightMinusMargins) {
                        doc.addPage();
                        yPos = marginLeft;
                    }
                    yPos = writeWrappedText(`${entry.startDate} - ${entry.endDate}`, marginLeft, yPos);
                    for (const index of entry.bulletPointIndices.slice(0, 4)) {
                        if (yPos > pageHeightMinusMargins) {
                            doc.addPage();
                            yPos = marginLeft;
                        }
                        yPos = writeWrappedText(`• ${entry.bulletPoints[index]}`, marginLeft, yPos);
                        if (yPos > pageHeightMinusMargins) {
                            doc.addPage();
                            yPos = marginLeft;
                        }
                    }
                }
            }
            // Generate a unique filename for the resume PDF
            const uniqueFilename = `InstaResume.pdf`;



            // Create a Blob from the PDF
            const pdfBlob = doc.output('blob');

            // Clean up the temporary download link
            setTimeout(() => {
                URL.revokeObjectURL(pdfBlob);
            }, 100);

            // Create a download link and trigger the download
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(pdfBlob);
            downloadLink.download = uniqueFilename;
            downloadLink.click();

            // Clean up the temporary download link
            setTimeout(() => {
                URL.revokeObjectURL(downloadLink.href);
            }, 100);
        } catch (error) {
            console.error('Error generating or downloading PDF:', error);
            // Handle the error appropriately (e.g., show an error message to the user)
        }
    };



    return (
        <Chip
            label="Download PDF"
            clickable
            onClick={() => handleDownload(name, phone, email, educationArray, workHistory)}
            icon={<GetAppIcon />}
            style={{ height: '50px', width: '150px' }}
        />
    );
}

export default DownloadButton;


