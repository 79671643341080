import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './assets/css/App.css';
import ResumeGenerator from './components/Resume/ResumeGenerator';  // Updated path
import Modal from './components/Auth/Modal';  // Updated path
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
//import RootLayout from './components/RootLayout';
import HomePage from './pages/HomePage';



function App() {
  return (
    <div className='App'>
      {/* Wrap everything inside a Router */}
      <Router>
        {/* Add AppBar and other global layout components here */}
        <Modal />
        {/* Define your Routes */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/resumegenerator" element={<ResumeGenerator />} />
          {/* Add more Routes as needed */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:uidb64/:token" element={<ResetPassword />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
