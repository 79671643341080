import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { containerVariants, headerVariants, ctaVariants } from '../transitions';
import AuthModal from '../components/Auth/AuthModal';
import '../assets/css/Home.css';
import { Link } from 'react-router-dom';
import logo from '../assets/Images/logo-no-background.png';

const HomePage = () => {
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState('signup');

  const openAuthModal = (mode) => {
    setModalMode(mode);
    setAuthModalOpen(true);
  };

  return (
    <div className="homepage">
      <motion.div initial="initial" animate="animate" transition={{ duration: 1 }} variants={containerVariants} className="motionDiv">
        <div className="leftSection">
          <div className="videoOuterContainer">
            <div className="videoContainer">
              <img src={logo} alt="Logo" className="videoStyle" />
            </div>
          </div>
        </div>
        {/* Content Section */}
        <div className="rightSection">
          <div className="redesignedContentContainer">
            <motion.h1 variants={headerVariants} transition={{ duration: 0.8 }} className="redesignedHeaderStyle">
              InstaResume
            </motion.h1>
            <motion.p className="redesignedSubHeaderStyle">
              Healthcare Recruiting Simplified
            </motion.p>
            <div className="ctaGroup">
              <motion.button onClick={() => openAuthModal('signup')} variants={ctaVariants} whileHover="hover" transition={{ duration: 0.3 }} className="redesignedButtonStyle">
                Sign Up
              </motion.button>
              <motion.button onClick={() => openAuthModal('login')} variants={ctaVariants} whileHover="hover" transition={{ duration: 0.3 }} className="redesignedButtonStyle secondaryButton">
                Log in
              </motion.button>
              <Link to="/resumegenerator" className="redesignedRouteButton">
                No account? Try without signing up.
              </Link>
            </div>
          </div>
        </div>
        <AuthModal isOpen={isAuthModalOpen} mode={modalMode} onCloseModal={() => setAuthModalOpen(false)} />
      </motion.div>
    </div>
  );
};

export default HomePage;