import React, { useState } from 'react';

const SavedResumes = ({ resumes, onView, onEdit, onDelete }) => {
    const [filterType, setFilterType] = useState('alphabetical'); // Default to alphabetical

    const sortedResumes = resumes.slice().sort((a, b) => {
        switch (filterType) {
            case 'alphabetical':
                return a.name.localeCompare(b.name);
            case 'date':
                // Assuming each resume has a 'date' property in 'YYYY-MM-DD' format
                return new Date(b.date) - new Date(a.date);
            case 'unit':
                // Assuming each resume has a 'unit' property
                return a.unit.localeCompare(b.unit);
            default:
                return 0;
        }
    });

    return (
        <div className="savedResumesContainer">
            <h2>Saved Resumes</h2>
            <div className="filterOptions">
                <label>Filter by: </label>
                <select value={filterType} onChange={(e) => setFilterType(e.target.value)}>
                    <option value="alphabetical">Alphabetical</option>
                    <option value="date">Date</option>
                    <option value="unit">Unit</option>
                </select>
            </div>
            {sortedResumes.length === 0 ? (
                <p>No saved resumes found.</p>
            ) : (
                <ul>
                    {sortedResumes.map((resume, index) => (
                        <li key={index}>
                            <span>{resume.name}</span>
                            <button onClick={() => onView(resume)}>View</button>
                            <button onClick={() => onEdit(resume)}>Edit</button>
                            <button onClick={() => onDelete(resume.id)}>Delete</button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SavedResumes;
