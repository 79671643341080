import React from 'react';

const WhatsNew = () => {
    const newFeatures = [
        'Added Bed Count, Trauma Level, and EMR System to Work History',
        'Added this What\'s New section',
        'Added instructions to the top of the page',
        'Improved performance and faster loading times for Downloading Resumes',
        'Enhanced the User Interface and Unveiled the new logo',
    ];

    return (
        <div className="whats-new">
            <h2>What's New</h2>
            <ul>
                {newFeatures.map((feature, index) => (
                    <li key={index}>{feature}</li>
                ))}
            </ul>
        </div>
    );
};

export default WhatsNew;