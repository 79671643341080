import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WorkHistoryEntry from './WorkHistoryEntry';
import { getAllBulletPoints } from './bulletPointsData';

function WorkHistorySection({ workHistory, setWorkHistory }) {
    const [hovered, setHovered] = useState(false);
    const addNewEntry = () => {
        setWorkHistory(workHistory.concat({
            facilityName: "",
            location: "",
            unit: "",
            startDate: "",
            endDate: "",
            bulletPoints: [],
            id: Math.random().toString(36).substr(2, 9), // Unique ID for new entry
        }));
    };

    const setEntryData = (entryIndex, key, value) => {
        const updatedWorkHistory = workHistory.map((entry, index) => (
            index === entryIndex ? { ...entry, [key]: value, ...(key === 'unit' && { bulletPoints: getAllBulletPoints(value) }) } : entry
        ));
        setWorkHistory(updatedWorkHistory);
    };

    const handleDeleteEntry = (index) => {
        const filteredWorkHistory = workHistory.filter((_, i) => i !== index);
        setWorkHistory(filteredWorkHistory);
    };

    return (
        <Accordion sx={{ background: 'transparent', boxShadow: 'none', marginBottom: '20px' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-work-history-content"
                id="panel-work-history-header"
                sx={{ background: 'transparent' }}
            >
                <Typography>Work History</Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    display: 'flex', // Make it a flex container
                    flexDirection: 'column', // Stack children vertically
                    alignItems: 'center', // Center children horizontally
                    background: 'transparent',
                    maxHeight: '500px', // Set a max height for scrolling
                    overflowY: hovered ? 'scroll' : 'hidden', // Conditional overflow based on hover
                }}
                onMouseEnter={() => setHovered(true)} // Set hovered to true when mouse enters
                onMouseLeave={() => setHovered(false)} // Set hovered to false when mouse leaves
            >
                {workHistory.map((entry, index) => (
                    <WorkHistoryEntry
                        key={entry.id || index}
                        entry={entry}
                        setEntryData={(key, value) => setEntryData(index, key, value)}
                        onDelete={() => handleDeleteEntry(index)}
                    />
                ))}
                <Button
                    variant="contained"
                    startIcon={<AddCircleIcon />}
                    onClick={addNewEntry}
                    sx={{ marginTop: 2, display: 'block' }} // Remove alignSelf if it's unnecessary
                >
                    Add Work History
                </Button>
            </AccordionDetails>

        </Accordion>
    );
}

export default WorkHistorySection;